import { DEPLOYMENT_VERSION } from "./../constants/enums";
import { Auth } from "aws-amplify";
import { LOCAL_STORAGE, Pages } from "../constants/enums";
import {
  getBookingDateRange,
  setBookingDateRange,
} from "../helpers/localStorage";
import {
  clearBrowser,
  getDateFormatted,
  getDomainName,
} from "../helpers/utils";
import { Account, Concept, Feature, UserConcepts } from "../models";
import {
  AdminPermissions,
  ConceptListingVariables,
  IBookingsSyncDuration,
  ListingVariables,
  UserConceptsListingVariables,
} from "../models/app";
import useAccount from "./useAccount";
import useAdminGroup from "./useAdminGroup";
import useApp from "./useApp";
import useConcept from "./useConcept";
import useFeature from "./useFeature";
import usePermissions from "./usePermissions";
import useUserConcepts from "./useUserConcepts";
import { useSelector } from "react-redux";

const useAuth = () => {
  const {
    showError,
    showConfirm,
    setSession,
    fetchSession,
    clearSession,
    navigateTo,
    setPermissions,
    setConcepts,
  } = useApp();
  const { getAdminPermissions } = usePermissions();
  const { userConceptsFetch } = useUserConcepts("userConcepts", "userConcept");

  const { accountsFetchByDomainOnline } = useAccount("accounts", "account");
  const { adminGroupsFetchCurrentUser } = useAdminGroup(
    "adminGroups",
    "adminGroup"
  );
  const { featuresFetch, featuresChangeListingAll } = useFeature(
    "features",
    "feature"
  );
  const { conceptsChangeSelected, conceptsChangeListing, conceptsFetch } =
    useConcept("concepts", "concepts");

  const userConcepts: UserConcepts = useSelector(
    (state: any) => state.app.concepts
  );

  async function register(data: any) {
    try {
      const accountsSelected: Account = await accountsFetchByDomainOnline(
        false
      );
      await Auth.signUp({
        username: `${getDomainName(accountsSelected)}_${data.email}`,
        password: data.password,
        attributes: {
          name: data.name,
          email: `${getDomainName(accountsSelected)}_${data.email}`,
        },
      });

      navigateTo("/");
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      // console.log("error signing up:", err);
    }
  }

  async function login(data: any) {
    const filteredConcepts: UserConcepts[] = [];

    const params: ListingVariables = {
      searchText: "",
      startIndex: 0,
      limit: 1000,
    };

    try {
      console.log("Login Start");
      const accountsSelected: Account = await accountsFetchByDomainOnline(
        false
      );
      console.log(accountsSelected);
      const result = await Promise.all([
        featuresFetch(params),
        Auth.signIn({
          username: `${getDomainName(accountsSelected)}_${data.email}`,
          password: data.password,
        }),
      ]);

      // const accountsSelected: Account = result[0];
      const featuresListingAll: Feature[] = result[0];

      const user: any = result[1];

      featuresChangeListingAll(featuresListingAll);

      const userConceptParams: UserConceptsListingVariables = {
        ...params,
        userID: user.signInUserSession.idToken.payload.sub,
      };
      console.log(userConceptParams);

      const conceptsParams: ConceptListingVariables = {
        ...params,
        accountID: accountsSelected.id,
      };
      console.log("still going");
      const resultData: any[] = await Promise.all([
        userConceptsFetch(userConceptParams),
        conceptsFetch(conceptsParams),
        adminGroupsFetchCurrentUser(user.signInUserSession.idToken.payload.sub),
      ]);

      console.log(resultData);

      const userConcepts: UserConcepts = resultData[0][0];
      const allConcepts: Concept[] = resultData[1];
      const userGroup = resultData[2];

      const permissions: AdminPermissions = await getAdminPermissions({
        userGroup,
      });
      console.log(permissions);

      setPermissions(permissions);

      if (userConcepts) {
        for (let concept of allConcepts) {
          if (userConcepts.concepts!.includes(concept.id)) {
            filteredConcepts.push(concept);
          }
        }

        setConcepts(userConcepts);
      }

      conceptsChangeListing(allConcepts);

      const storageConcept: string | null = localStorage.getItem(
        LOCAL_STORAGE.SELECTED_CONCEPT
      );

      if (storageConcept) {
        conceptsChangeSelected(storageConcept);
      } else {
        if (filteredConcepts.length > 0) {
          for (var j = 0; j < filteredConcepts.length; j++) {
            if (
              userConcepts &&
              userConcepts.concepts &&
              userConcepts.concepts.includes(filteredConcepts[j].id)
            ) {
              conceptsChangeSelected(filteredConcepts[j].id);
              break;
            }
          }
        }
      }

      const bookingsSyncDuration: IBookingsSyncDuration =
        getBookingDateRange(userConcepts);
      const { fromDate, toDate } = bookingsSyncDuration;
      setBookingDateRange(fromDate, toDate);

      localStorage.setItem(LOCAL_STORAGE.BOOKING_REFRESH, "false");
      localStorage.setItem(LOCAL_STORAGE.BOOKING_GUEST, "false");
      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE_RANGE, "false");
      localStorage.setItem(LOCAL_STORAGE.GUESTS_SEARCH, "false");
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUEST_STATS, "false");
      localStorage.setItem(LOCAL_STORAGE.SYNC_BOOKINGS, "true");
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUESTS, "true");
      localStorage.setItem(
        LOCAL_STORAGE.BOOKING_DATE,
        getDateFormatted(new Date())
      );

      setPermissions(permissions);
      setSession(user.signInUserSession.idToken.payload);
    } catch (err: Error | any) {
      console.log({ err: err.errors });
      if (
        err.errors &&
        err.errors[0].message ===
          "Not Authorized to access listAccounts on type Query"
      ) {
        showError("Error Occurred, Please try to login again");
        await clearBrowser();
      } else {
        showError(
          typeof err.message === "string" ? err.message : "Error Occurred"
        );
      }
    }
  }

  async function loginSync() {
    try {
      const bookingsSyncDuration: IBookingsSyncDuration =
        getBookingDateRange(userConcepts);
      const { fromDate, toDate } = bookingsSyncDuration;
      setBookingDateRange(fromDate, toDate);

      localStorage.setItem(LOCAL_STORAGE.BOOKING_REFRESH, "false");
      localStorage.setItem(LOCAL_STORAGE.BOOKING_GUEST, "false");
      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE_RANGE, "false");
      localStorage.setItem(LOCAL_STORAGE.GUESTS_SEARCH, "false");
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUEST_STATS, "false");
      localStorage.setItem(LOCAL_STORAGE.SYNC_BOOKINGS, "true");
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUESTS, "true");
      localStorage.setItem(
        LOCAL_STORAGE.BOOKING_DATE,
        getDateFormatted(new Date())
      );
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
    }
  }

  async function logout() {
    try {
      localStorage.clear();

      await Auth.signOut();

      clearSession();
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      // console.log("error signing out: ", err);
    }
  }

  async function checkDeploymentVersion() {
    try {
      // Get current version from local storage
      const currentVersion: string | null = localStorage.getItem(
        LOCAL_STORAGE.VERSION
      );

      // Compare it with constant version
      console.log({ currentVersion, DEPLOYMENT_VERSION });
      const currentTime = getCurrentTime();
      if (currentVersion === null || currentVersion < DEPLOYMENT_VERSION) {
        // Get value stored in session
        await logout();
        await refreshCacheAndReload();
        await clearBrowser(true);

        localStorage.setItem(LOCAL_STORAGE.VERSION, DEPLOYMENT_VERSION);
        localStorage.setItem(LOCAL_STORAGE.LAST_VISIT, String(currentTime));

        return true;
      }
      // else if (storedTime === null || is24HoursPassed(Number(storedTime))) {
      //   // Do something if 24 hours have passed
      //   console.log("24 hours have passed since the last visit.");

      //   // Get value stored in session
      //   await logout();
      //   await refreshCacheAndReload();
      //   await clearBrowser(true);

      //   localStorage.setItem(LOCAL_STORAGE.VERSION, DEPLOYMENT_VERSION);
      //   localStorage.setItem(LOCAL_STORAGE.LAST_VISIT, String(currentTime));
      //   return true;
      // }

      return false;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );

      return false;
    }
  }

  // Function to get the current time in milliseconds
  const getCurrentTime = () => new Date().getTime();

  // Function to check if 24 hours have passed
  const is24HoursPassed = (storedTime: number) => {
    const currentTime = getCurrentTime();
    const hoursInMilliseconds = 48 * 60 * 60 * 1000; // 24 hours in milliseconds
    return currentTime - storedTime >= hoursInMilliseconds;
  };

  async function checkLastLoginTime() {
    try {
      const storedTime: string | null = localStorage.getItem(
        LOCAL_STORAGE.LAST_VISIT
      );

      // console.log({ storedTime });

      // Check if 24 hours have passed since the last visit
      const currentTime = getCurrentTime();
      if (storedTime === null || is24HoursPassed(Number(storedTime))) {
        // Do something if 24 hours have passed
        console.log("24 hours have passed since the last visit.");

        await logout();
        await refreshCacheAndReload();
        await clearBrowser(true);

        // Store the current time in localStorage
        localStorage.setItem(LOCAL_STORAGE.VERSION, DEPLOYMENT_VERSION);
        localStorage.setItem(LOCAL_STORAGE.LAST_VISIT, String(currentTime));

        console.log("Update last visit");
      }
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
    }
  }

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
  };

  async function forgetPassword(data: any) {
    try {
      const accountsSelected: Account = await accountsFetchByDomainOnline(
        false
      );
      // Send confirmation code to user's email
      await Auth.forgotPassword(
        `${getDomainName(accountsSelected)}_${data.email}`
      );
      showConfirm("Verification code has been sent to your email.");
      navigateTo(`/${Pages.RESET_PASSWORD}`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      // console.log("error sending code to email: ", err);
    }
  }

  async function forgetPasswordSubmit(data: any) {
    try {
      const accountsSelected: Account = await accountsFetchByDomainOnline(
        false
      );
      await Auth.forgotPasswordSubmit(
        `${getDomainName(accountsSelected)}_${data.email}`,
        data.code,
        data.newPassword
      );
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      // console.log(err);
    }
  }

  return {
    loginSync,
    login,
    register,
    logout,
    checkDeploymentVersion,
    checkLastLoginTime,
    forgetPassword,
    forgetPasswordSubmit,
  };
};

export default useAuth;
