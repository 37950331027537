import { UpdateBookingInput } from "./../API";
import {
  BookingBulkTrashVariables,
  BookingCommentsVariables,
  BookingGetVariables,
  BookingGuestsVariables,
  BookingListingVariables,
  BookingUpdateVariables,
  CreateBookingGuestVariables,
  CreateBookingVariables,
  CreateVariables,
  GetVariables,
  GroupGetVariables,
  ListingVariables,
  NotificationAutoSendVariables,
  PlanItemUpdateVariables,
  ReleaseTablesVariables,
  SyncUserGroupParams,
  TimeSlotGetVariables,
  UpdateCustomerFlagVariables,
  UpdateCustomerStatsVariables,
  UpdatePlanTablesVariables,
} from "./../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API, DataStore, SortDirection } from "aws-amplify";
import { GraphQLResult, GraphQLQuery } from "@aws-amplify/api";
import * as queries from "../graphql/queries";

import { useDispatch, useSelector } from "react-redux";
import {
  setComments,
  setDate,
  setFlags,
  setGuestsCount,
  setListing,
  setListingChannels,
  setListingRushDays,
  setListingSlots,
  setListingSlotsNext,
  setListingSlotsPrev,
  setListingStats,
  setMainGuest,
  setPendingListing,
  setSelected,
  setStatus,
  setTables,
  setTimes,
} from "../store/ducks/booking";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import {
  Booking,
  BookingGuest,
  ReservationStatus,
  TimeSlot,
  UserPushToken,
  UserConcepts,
} from "../models";
import {
  BOOKING_STATUS,
  Channels,
  LOCAL_STORAGE,
  Orders,
} from "../constants/enums";
import {
  getComparatorLowerCase,
  stableSort,
  getDateFormatted,
  getDayName,
  isEquivalentArrays,
  persistBookingDate,
  validatePhone,
} from "../helpers/utils";
import useComment from "./useComment";
import useStatus from "./useStatus";
import usePlanItem from "./usePlanItem";
import useUser from "./useUser";
import useTimeline from "./useTimeline";
import useTimeSlot from "./useTimeSlot";
import useGroup from "./useGroup";
import useBookingGuest from "./useBookingGuest";
import { listBookings } from "../graphql/queries";
import { updateBooking } from "../graphql/mutations";
import { CreateBookingInput, ModelSortDirection } from "../models/GQL_API";
import useNotification from "./useNotification";

const useResource = (listingName: string, singleName: string) => {
  const session = useSelector((state: any) => state.app.session);
  const dispatch = useDispatch();
  const bookings = useSelector((state: any) => state.bookings.listing);
  const statusesSelected = useSelector((state: any) => state.statuses.selected);
  const timeSlotsListing = useSelector((state: any) => state.timeSlots.listing);
  const timeSlotsSelected = useSelector(
    (state: any) => state.timeSlots.selected
  );
  const conceptsSelected = useSelector((state: any) => state.concepts.selected);
  const conceptsSelectedFilters = useSelector(
    (state: any) => state.concepts.selectedFilters
  );
  const conceptsListing = useSelector((state: any) => state.concepts.listing);
  const timeSlotsListingAll: TimeSlot[] = useSelector(
    (state: any) => state.timeSlots.listingAll
  );

  const statusesListing = useSelector((state: any) => state.statuses.listing);
  const bookingsDate = useSelector((state: any) => state.bookings.date);
  const {
    bookingGuestsFetchWithGuestList,
    bookingGuestsFetchGuestsByBookingIDs,
  } = useBookingGuest("bookingGuests", "bookingGuest");

  const { showConfirm, showWarning, showError } = useApp();
  const { commentsCreate } = useComment("comments", "comment");
  const { planItemsGet, planItemsGetNames, planItemsUpdate } = usePlanItem(
    "planItems",
    "planItem"
  );
  // eslint-disable-next-line
  const { notificationsCheckAutoSendStatus } = useNotification(
    "notifications",
    "notification"
  );

  const { timeSlotsGetName } = useTimeSlot("timeSlots", "timeSlot");
  const { guestsUpdateOnline, guestsGet } = useUser("guests", "guest");
  const { statusesGetName } = useStatus("statuses", "status");
  const { timelinesCreate } = useTimeline("timelines", "timelines");
  const { groupsGet } = useGroup("groups", "group");

  const { bookingGuestsCreate, bookingGuestsUpdateDepositValue } =
    useBookingGuest("bookingGuests", "bookingGuest");
  const userConcepts: UserConcepts = useSelector(
    (state: any) => state.app.concepts
  );

  async function fetch(props: BookingListingVariables) {
    const {
      startIndex,
      limit,
      fromDate,
      toDate,
      bookingDate,
      conceptID,
      conceptsSelectedFilters,
    } = props;

    let sIndex: number = 0;
    let perPage: number = 10;

    if (startIndex) sIndex = startIndex;
    if (limit) perPage = limit;

    try {
      // Generate Filter Object
      const dateFilter: boolean = false;
      const conceptFilter: boolean = false;
      const filter: any = await applyFilters(props, dateFilter, conceptFilter);

      let listing: any[] = [];
      // Concepts Filter
      let concepts = [];
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        if (typeof conceptsSelectedFilters[0] === "string") {
          concepts = conceptsSelectedFilters;
        } else {
          for (let filter of conceptsSelectedFilters) {
            concepts.push(filter.id);
          }
        }
      } else {
        concepts = [conceptID];
      }

      // Date Filter
      let bookingDateFilter = {};
      if (fromDate && toDate) {
        bookingDateFilter = {
          between: [fromDate, toDate],
        };
      } else if (bookingDate) {
        bookingDateFilter = { eq: bookingDate };
      }

      for (let concept of concepts) {
        const listData: any = await API.graphql({
          query: queries.bookingByConcept,
          variables: {
            conceptID: concept,
            date: bookingDateFilter,
            filter,
            sortDirection: ModelSortDirection.ASC,
            limit: 1000,
          },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        listing = [...listing, ...listData.data.bookingByConcept.items];
      }
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchOffline(props: BookingListingVariables) {
    const {
      conceptID,
      searchText,
      startIndex,
      limit,
      fromDate,
      toDate,
      bookingDate,
      timeSlot,
      timeSlots,
      guestID,
      stats,
      requiresDeposit,
      statusesSelectedFilters,
      groupsSelectedFilters,
      conceptsSelectedFilters,
      statusesListing,
      tables,
    } = props;

    let sIndex: number = 0;
    let perPage: number = 10;

    if (startIndex) sIndex = startIndex;
    if (limit) perPage = limit;

    try {
      const listing: Booking[] = await DataStore.query(
        Booking as any,
        (booking: any) => {
          booking.deleted("eq", "0");

          if (requiresDeposit) booking.depositStatus("eq", requiresDeposit);

          // Concepts Filter
          if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
            booking.or((model: any) => {
              for (let filter of conceptsSelectedFilters) {
                model.conceptID("eq", filter.id);
              }
            });
          } else {
            if (conceptID) booking.conceptID("eq", conceptID);
          }

          if (fromDate && toDate)
            booking.date("ge", fromDate).date("le", toDate);

          if (bookingDate) booking.date("eq", bookingDate);
          if (timeSlot) booking.timeSlotID("eq", timeSlot);

          if (stats && stats.length > 0) {
            booking.or((booking: any) => {
              for (let filter of stats) {
                booking.id("eq", filter);
              }
            });
          }

          if (timeSlots) {
            booking.or((booking: any) => {
              for (let filter of timeSlots) {
                booking.timeSlots("contains", filter);
              }
            });
          }

          if (tables) {
            booking.or((booking: any) => {
              for (let filter of tables) {
                booking.tables("contains", filter);
              }
            });
          }

          if (statusesSelectedFilters && statusesSelectedFilters.length > 0) {
            booking.or((booking: any) => {
              for (let filter of statusesSelectedFilters) {
                booking.statusID("eq", filter.id);
              }
            });
          }

          if (groupsSelectedFilters && groupsSelectedFilters.length > 0) {
            booking.or((booking: any) => {
              for (let filter of groupsSelectedFilters) {
                booking.customerGroup("eq", filter.name);
              }
            });
          }

          if (guestID) booking.mainGuest("eq", guestID);

          if (searchText && searchText.length > 0) {
            booking.or((booking: any) => {
              booking.customerName("contains", searchText.toLowerCase());
              booking.customerPhone("contains", searchText.toLowerCase());
            });
          }

          return booking;
        },
        {
          page: sIndex / perPage,
          limit: perPage,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAll(props: ListingVariables) {
    const { startIndex, limit } = props;

    let sIndex: number = 0;
    let perPage: number = 10;

    if (startIndex) sIndex = startIndex;
    if (limit) perPage = limit;

    try {
      const listing: Booking[] = await DataStore.query(
        Booking as any,
        (booking: any) => {
          booking.deleted("eq", "0");

          return booking;
        },
        {
          page: sIndex / perPage,
          limit: perPage,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchOnline(params: any) {
    const { id } = params;
    const filter: any = {
      deleted: { eq: "0" },
      mainGuest: { eq: id },
    };

    try {
      const listing: any = await API.graphql({
        query: listBookings,
        variables: { filter },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return listing.data.listBookings.items;
    } catch (err) {
      throw err;
    }
  }

  async function fetchByConcept(props: any) {
    try {
      let listing: any[] = [];
      const {
        fromDate,
        toDate,
        bookingDate,
        conceptID,
        conceptsSelectedFilters,
      } = props;

      // Generate Filter Object
      const dateFilter: boolean = false;
      const conceptFilter: boolean = false;
      const filter: any = await applyFilters(props, dateFilter, conceptFilter);

      // Concepts Filter
      let concepts = [];
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        concepts = conceptsSelectedFilters;
      } else {
        concepts = [conceptID];
      }

      // Date Filter
      let bookingDateFilter = {};
      if (fromDate && toDate) {
        bookingDateFilter = {
          between: [fromDate, toDate],
        };
      } else if (bookingDate) {
        bookingDateFilter = { eq: bookingDate };
      }

      for (let concept of concepts) {
        const listData: any = await API.graphql({
          query: queries.bookingByConcept,
          variables: {
            conceptID: concept,
            date: bookingDateFilter,
            filter,
            sortDirection: ModelSortDirection.ASC,
            limit: 1000,
          },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        listing = [...listing, ...listData.data.bookingByConcept.items];
      }

      return listing;
    } catch (err) {
      throw err;
    }
  }

  async function applyFilters(
    props: any,
    dateFilter: boolean,
    conceptFilter: boolean
  ) {
    const filter: any = {
      deleted: { eq: "0" },
    };

    try {
      const {
        conceptID,
        searchText,
        fromDate,
        toDate,
        bookingDate,
        timeSlot,
        timeSlots,
        guestID,
        stats,
        requiresDeposit,
        statusesSelectedFilters,
        groupsSelectedFilters,
        conceptsSelectedFilters,
        tables,
      } = props;

      // Filter Section
      let and = [];

      if (guestID) filter.mainGuest = { eq: guestID };
      if (requiresDeposit) filter.depositStatus = { eq: requiresDeposit };

      // Date Filter
      if (dateFilter) {
        if (fromDate && toDate) {
          and.push({
            date: {
              ge: fromDate,
            },
          });
          and.push({
            date: {
              lt: toDate,
            },
          });
        }

        if (bookingDate) {
          and.push({
            date: {
              eq: bookingDate,
            },
          });
        }
      }

      // Concepts Filter
      if (conceptFilter) {
        if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
          let or = [];
          for (let concept of conceptsSelectedFilters) {
            or.push({ conceptID: { eq: concept.id } });
          }

          and.push({ or: or });
        } else {
          filter.conceptID = { eq: conceptID };
        }
      }

      // Stats Filter (Booking Ids)
      if (stats && stats.length > 0) {
        let or = [];

        for (let stat of stats) {
          or.push({ id: { eq: stat } });
        }
        and.push({ or: or });
      }

      // Time Slots Filter
      if (timeSlot) filter.timeSlotID = { eq: timeSlot };
      if (timeSlots && timeSlots.length > 0) {
        let or = [];

        for (let timeSlot of timeSlots) {
          or.push({ timeSlots: { contains: timeSlot } });
        }
        and.push({ or: or });
      }

      // Tables Filter
      if (tables && tables.length > 0) {
        let or = [];

        for (let table of tables) {
          or.push({ tables: { contains: table } });
        }
        and.push({ or: or });
      }

      // Booking Status Filter
      if (statusesSelectedFilters && statusesSelectedFilters.length > 0) {
        let or = [];

        for (let filter of statusesSelectedFilters) {
          or.push({ statusID: { eq: filter.id } });
        }
        and.push({ or: or });
      }

      // Groups Filter
      if (groupsSelectedFilters && groupsSelectedFilters.length > 0) {
        let or = [];

        for (let filter of groupsSelectedFilters) {
          or.push({ customerGroup: { eq: filter.name } });
        }
        and.push({ or: or });
      }

      // Search Filter
      if (searchText && searchText.length > 0) {
        let or = [];

        or.push({ customerName: { contains: searchText.toLowerCase() } });
        or.push({ customerPhone: { contains: searchText.toLowerCase() } });

        and.push({ or: or });
      }

      if (and.length > 0) {
        filter.and = and;
      }

      return filter;
    } catch (err: Error | any) {
      showError(err.message || err);
      return filter;
    }
  }

  /* Fetch all booking, Booked for given date (Data Format: yyyy-MM-dd) */
  async function fetchByBookingDate(props: any) {
    try {
      let listing: any[] = [];
      const { fromDate, toDate, conceptID, conceptsSelectedFilters } = props;

      const filter: any = {
        deleted: { eq: "0" },
      };

      // Concepts Filter
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let concept of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: concept.id } });
        }
        filter.or = or;
      } else {
        filter.conceptID = { eq: conceptID };
      }

      // Loop over booking date range
      const startDate = new Date(fromDate);
      const endDate = new Date(toDate);

      let currentDate = startDate;

      while (currentDate <= endDate) {
        const listData: any = await API.graphql({
          query: queries.BookingDate,
          variables: {
            date: getDateFormatted(currentDate),
            filter,
            // sortDirection: ModelSortDirection.ASC,
            limit: 1000,
          },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        listing = [...listing, ...listData.data.BookingDate.items];
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return listing;
    } catch (err) {
      throw err;
    }
  }

  /* Fetch all booking, Booked for given date (Data Format: yyyy-MM-dd) */
  async function fetchBookingPerWeek(props: any) {
    try {
      let listing: any[] = [];
      const { fromDate, toDate, conceptID, conceptsSelectedFilters } = props;

      const filter: any = {
        deleted: { eq: "0" },
      };

      // Concepts Filter
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let concept of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: concept.id } });
        }
        filter.or = or;
      } else {
        filter.conceptID = { eq: conceptID };
      }

      if (fromDate && toDate) {
        const today = new Date(fromDate);
        const dayOfWeek = today.getDay(); // 0 (Sunday) through 6 (Saturday)

        // Calculate the start of the week (Sunday)
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - dayOfWeek);

        // Calculate the end of the week (Saturday)
        const endOfWeek = new Date(today);
        endOfWeek.setDate(today.getDate() + (6 - dayOfWeek));

        let currentDate = startOfWeek;

        while (currentDate <= endOfWeek) {
          const listData: any = await API.graphql({
            query: queries.BookingDate,
            variables: {
              date: getDateFormatted(currentDate),
              filter,
              limit: 1000,
            },
            authMode: session
              ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
              : GRAPHQL_AUTH_MODE.AWS_IAM,
          });

          listing = [...listing, ...listData.data.BookingDate.items];
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }

      return listing;
    } catch (err) {
      throw err;
    }
  }

  type DuplicatesParams = {
    conceptID: string;
    bookingDate: string;
    timeSlots: string[];
    tables?: any[];
    bookingID?: string;
  };

  async function isExists(props: DuplicatesParams): Promise<boolean> {
    const { conceptID, bookingDate, timeSlots, tables, bookingID } = props;

    const bookingsQueryVariables: BookingListingVariables = {
      conceptID,
      searchText: "",
      startIndex: 0,
      limit: 1000,
      bookingDate,
      timeSlots,
      tables,
      bookingID,
    };

    try {
      const bookings: Booking[] | undefined = await fetch(
        bookingsQueryVariables
      );

      if (bookings && bookings.filter((b) => b.id !== bookingID).length > 0)
        return bookings.length > 0;

      return false;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function syncUserGroup(params: SyncUserGroupParams) {
    const {
      accountID,
      conceptID,
      userID,
      guestSelected,
      guestsListing,
      groupsListing,
      statusesListing,
      interestsListing,
      flagsListing,
      timeSlotsListing,
      timelinesListing,
    } = params;

    if (guestSelected && guestSelected.group) {
      const groupsParam: GroupGetVariables = {
        id: guestSelected.group,
        listing: groupsListing,
      };
      const userGroup = await groupsGet(groupsParam);

      let upgradeGroup;
      let downgradeGroup;
      let numToDowngrade;
      let numToUpgrade;

      const params: BookingListingVariables = {
        conceptID: conceptID,
        searchText: "",
        startIndex: 0,
        limit: 1000,
        stats: guestSelected.stats!,
      };
      const userBookings = await fetch(params);

      const userCheckIns: number =
        userBookings !== undefined
          ? userBookings.filter(
              (item: any) =>
                statusesGetName({
                  id: item.statusID,
                  listing: statusesListing,
                }) === "Check In"
            ).length
            ? userBookings.filter(
                (item: any) =>
                  statusesGetName({
                    id: item.statusID,
                    listing: statusesListing,
                  }) === "Check In"
              ).length
            : 0
          : 0;
      const userNoShows: number =
        userBookings !== undefined
          ? userBookings.filter(
              (item: any) =>
                statusesGetName({
                  id: item.statusID,
                  listing: statusesListing,
                }) === "No Show"
            ).length
            ? userBookings.filter(
                (item: any) =>
                  statusesGetName({
                    id: item.statusID,
                    listing: statusesListing,
                  }) === "No Show"
              ).length
            : 0
          : 0;
      if (userGroup !== undefined && userGroup.upgradeGroup)
        upgradeGroup = userGroup.upgradeGroup;
      if (userGroup !== undefined && userGroup.downgradeGroup)
        downgradeGroup = userGroup.downgradeGroup;
      if (userGroup !== undefined && userGroup.numToDowngrade)
        numToDowngrade = userGroup.numToDowngrade;
      if (userGroup !== undefined && userGroup.numToUpgrade)
        numToUpgrade = userGroup.numToUpgrade;

      if (userCheckIns > 0 && userNoShows > 0 && userCheckIns === userNoShows) {
        return;
      }

      if (
        (!upgradeGroup && !downgradeGroup) ||
        (upgradeGroup === "-1" && downgradeGroup === "-1") ||
        (upgradeGroup === null && downgradeGroup === null)
      ) {
        return;
      }

      if (upgradeGroup !== "-1" && upgradeGroup !== null) {
        if (userCheckIns >= numToUpgrade) {
          const updateInput = {
            userID: userID,
            group: upgradeGroup,
            accountID,
            interestsListing,
            flagsListing,
            statusesListing,
            timeSlotsListing,
            timelinesListing,
          };
          await guestsUpdateOnline({
            id: userID,
            listing: guestsListing,
            data: updateInput,
          });
          showConfirm(
            `User ${
              guestSelected.name ? guestSelected.name : ""
            } has been upgraded to ${upgradeGroup}`
          );
        }
      }

      if (downgradeGroup !== "-1" && downgradeGroup !== null) {
        if (userNoShows >= numToDowngrade) {
          const updateInput = {
            userID: userID,
            group: downgradeGroup,

            accountID,
            interestsListing,
            flagsListing,
            statusesListing,
            timeSlotsListing,
            timelinesListing,
          };
          await guestsUpdateOnline({
            id: userID,
            listing: guestsListing,
            data: updateInput,
          });
          showConfirm(
            `User ${
              guestSelected.name ? guestSelected.name : ""
            } has been downgraded to ${downgradeGroup}`
          );
        }
      }
    } else {
      showWarning("Error auto syncing user group upgrade/downgrade");
    }
  }

  async function exportWithGuestList(
    exportResource: (params: BookingListingVariables) => any
  ) {
    try {
      const params: BookingListingVariables = {
        conceptID: conceptsSelected,

        searchText: "",
        startIndex: 0,
        limit: 1000,
        bookingDate: getDateFormatted(persistBookingDate(bookingsDate)),
        statusesSelected,
        timeSlotsListing,
        statusesListing,
      };
      if (timeSlotsSelected.length > 0) {
        params.timeSlots = [timeSlotsSelected];
      }
      // const params1 = {
      //   bookingIDs: bookings.map((booking2: Booking) => booking2.id),
      //   bookingID: "",
      //   conceptID: "",
      //   transactionID: "",
      //   searchText: "",
      //   startIndex: 0,
      //   limit: 1000,
      // };
      // var guestsData: BookingGuest[] = await bookingGuestsFetchWithGuestList(
      //   params1
      // );

      // Filter booking that has eventGuests only
      const bookingIDs = bookings
        .filter(
          (booking2: Booking) =>
            booking2.eventGuests && booking2.eventGuests.length > 0
        )
        .map((booking2: Booking) => booking2.id);
      var guestsData: BookingGuest[] =
        await bookingGuestsFetchGuestsByBookingIDs(bookingIDs);

      const data: Booking[] = await exportResource(params);
      let bookingsList: Booking[] = data;

      if (statusesSelected.size > 0) {
        bookingsList = data.filter((model: Booking) =>
          statusesSelected.has(model.statusID!)
        );
      }
      const exportData: any[] = [];
      let bookingsListSorted = stableSort(
        bookingsList,
        getComparatorLowerCase(Orders.ASC, "customerName")
      );
      for (let booking of bookingsListSorted) {
        const slotNames: string[] = [];

        for (let timeSlotID of booking.timeSlots!) {
          const timeSlot: TimeSlot | undefined = timeSlotsListing.find(
            (model: TimeSlot) => model.id === timeSlotID
          );

          if (timeSlot) slotNames.push(timeSlot.friendlyName);
        }

        const commentsTexts: string[] = [];

        for (let bookingComment of booking.comments!) {
          let newComment = bookingComment!.replaceAll("<p>", "");
          newComment = newComment.replaceAll("</p>", "");

          commentsTexts.push(newComment);
        }

        exportData.push({ ...booking, commentsTexts, slotNames });

        var myGuests = guestsData.filter(
          (guest) => guest.bookingID === booking.id
        );

        if (myGuests.length > 0) {
          exportData.push({
            customerName: "Guest Name:",
            customerGroup: "Paid Amount",
            customerPhone: "Payment Status",
            accompaniedCount: "Transaction ID",
            createdBy: { name: "" },
            createdAt: booking.createdAt,
          });
        }
        let myGuestsSorted = stableSort(
          myGuests,
          getComparatorLowerCase(Orders.ASC, "guestName")
        );
        for (var i = 0; i < myGuestsSorted.length; i++) {
          exportData.push({
            customerName: myGuestsSorted[i].guestName,
            customerGroup:
              myGuestsSorted[i].paidAmount !== 0
                ? myGuestsSorted[i].paidAmount + ""
                : "0",
            customerPhone: myGuestsSorted[i].paymentStatus ? "Paid" : "UnPaid",
            accompaniedCount: myGuestsSorted[i].transactionID,
            createdBy: { name: "" },
            createdAt: booking.createdAt,
          });
        }
      }
      return exportData;
    } catch (err: Error | any) {
      console.log({
        err,
      });
      return [];
    }
  }

  async function exportAll(params: BookingListingVariables) {
    const data = await fetch(params);

    console.log({
      len: data.length,
    });

    let exportedData = [];

    for (let booking of data!) {
      let row: any = { ...booking };
      if (booking.timeSlotID) {
        const timeSlotGetParams: TimeSlotGetVariables = {
          id: booking.timeSlotID,
          listing: params.timeSlotsListing ? params.timeSlotsListing : [],
        };
        row.timeSlotName = timeSlotsGetName(timeSlotGetParams);
      }
      if (booking.statusID) {
        row.statusName = statusesGetName({
          id: booking.statusID,
          listing: params.statusesListing,
        });
      }
      exportedData.push(row);
    }

    return exportedData;
  }

  async function channels(props: BookingListingVariables) {
    const { conceptID, startIndex, limit, conceptsSelectedFilters } = props;

    try {
      const listing: Booking[] = await DataStore.query(
        Booking as any,
        (model: any) => {
          model.deleted("eq", "0");

          // Concepts Filter
          if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
            model.or((model: any) => {
              for (let filter of conceptsSelectedFilters) {
                model.conceptID("eq", filter.id);
              }
            });
          } else {
            model.conceptID("eq", conceptID);
          }

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );
      const channelList = [
        {
          name: Channels.WEB_CHANNEL,
          value: listing.filter(
            (item) =>
              item.channel?.toLowerCase() === Channels.WEB_CHANNEL.toLowerCase()
          ).length,
          fill: "#aaa",
        },
        {
          name: Channels.ANDROID_CHANNEL,
          value: listing.filter(
            (item) =>
              item.channel?.toLowerCase() ===
              Channels.ANDROID_CHANNEL.toLowerCase()
          ).length,
          fill: "#008850",
        },
        {
          name: Channels.IPHONE_CHANNEL,
          value: listing.filter(
            (item) =>
              item.channel?.toLowerCase() ===
              Channels.IPHONE_CHANNEL.toLowerCase()
          ).length,
          fill: "#000",
        },
        {
          name: Channels.WEBSITE,
          value: listing.filter(
            (item) =>
              item.channel?.toLowerCase() === Channels.WEBSITE.toLowerCase()
          ).length,
          fill: "#FFD700",
        },
        {
          name: Channels.FACEBOOK,
          value: listing.filter(
            (item) =>
              item.channel?.toLowerCase() === Channels.FACEBOOK.toLowerCase()
          ).length,
          fill: "#1E88E5",
        },
        {
          name: Channels.INSTAGRAM,
          value: listing.filter(
            (item) =>
              item.channel?.toLowerCase() === Channels.INSTAGRAM.toLowerCase()
          ).length,
          fill: "#FF6B6B",
        },
        {
          name: Channels.WHATSAPP,
          value: listing.filter(
            (item) =>
              item.channel?.toLowerCase() === Channels.WHATSAPP.toLowerCase()
          ).length,
          fill: "#25D366",
        },
      ];

      dispatch(setListingChannels(channelList));
      return channelList;
    } catch (err: Error | any) {
      throw err;
    }
  }

  function convertTo24HourFormat(time: any) {
    const [hour, minute] = time.split(/:|(?=[APap][Mm])/);
    const isPM = time.match(/[Pp][Mm]/);
    let hour24 = parseInt(hour, 10);

    if (isPM && hour24 !== 12) {
      hour24 += 12;
    } else if (!isPM && hour24 === 12) {
      hour24 = 0;
    }

    return `${hour24.toString().padStart(2, "0")}:${minute}`;
  }

  async function statistics(
    bookingListing: Booking[],
    statuses: ReservationStatus[],
    timeSlotsList: TimeSlot[]
  ) {
    try {
      let confirmedCounter = 0;
      let confirmedPaxCounter = 0;
      let reservedCounter = 0;
      let noShowCounter = 0;
      let waitingListCounter = 0;
      let cancelledCounter = 0;
      let pendingReviewCounter = 0;
      let pax = 0;
      let SeatedCounter = 0;
      const selectedConceptsIds = conceptsSelectedFilters.map(
        (item: any) => item.id
      );

      let UserConceptsListing = conceptsListing
        .filter((item: any) => userConcepts.concepts?.includes(item.id))
        .filter((concept: any) => selectedConceptsIds.includes(concept.id));

      let selectedSlots = timeSlotsList.filter((item: any) =>
        selectedConceptsIds.includes(item.conceptID)
      );
      let x = new Set(
        selectedSlots
          .sort((slot1: any, slot2: any) => {
            const time1 = slot1.name.split(" - ")[0];
            const time2 = slot2.name.split(" - ")[0];
            const convertedTime1 = convertTo24HourFormat(time1);
            const convertedTime2 = convertTo24HourFormat(time2);

            return convertedTime1.localeCompare(convertedTime2);
          })
          .map((item: any) => item.friendlyName)
      );

      const uniqueTimeSlotNames = Array.from(x);
      if (
        uniqueTimeSlotNames[1] === "Slot 1" &&
        uniqueTimeSlotNames[2] === "Lunch"
      ) {
        uniqueTimeSlotNames[2] = "Slot 1";
        uniqueTimeSlotNames[1] = "Lunch";
      }
      const uniqueTimeSlotObjects = uniqueTimeSlotNames.map((item) => {
        return { name: item, pax: 0, count: 0 };
      });
      const uniqueTimeSlotObjectsPerConcept = UserConceptsListing?.map(
        (concept: any) => {
          return {
            concept: concept.id,
            slots: uniqueTimeSlotNames?.map((item) => {
              return { name: item, pax: 0, count: 0 };
            }),
          };
        }
      );

      let timeSlots = timeSlotsListingAll.map((item: any) => {
        return { pax: 0, count: 0, id: item.id, name: item.friendlyName };
      });

      const ConceptObjs = UserConceptsListing.map((item: any) => ({
        id: item.id,
        name: item.name,
        value: 0, // Total column
        confirmed: 0,
        confirmedPax: 0,
        reserved: 0,
        pax: 0,
        waitinglist: 0,
        cancelled: 0,
        checkedIn: 0,
        pendingReview: 0,
        slots: uniqueTimeSlotObjectsPerConcept.find(
          (obj: any) => obj.concept === item.id
        ).slots,
      }));

      for (let i = 0; i < bookingListing.length; i++) {
        const num = bookingListing[i].accompaniedCount;

        let status = statusesGetName({
          id: bookingListing[i].statusID,
          listing: statuses,
        });

        let concept = ConceptObjs.find(
          (item: any) => item.id === bookingListing[i].conceptID
        );

        // Pax (Confirmed + Reserved) Only
        if (
          status === BOOKING_STATUS.CONFIRMED ||
          status === BOOKING_STATUS.RESERVED
        ) {
          const num = bookingListing[i].accompaniedCount;
          if (concept) concept.pax += num;
          pax = pax + (num ? num : 0);
        }

        switch (status) {
          case BOOKING_STATUS.CONFIRMED: {
            confirmedCounter = confirmedCounter + 1;
            if (num) {
              confirmedPaxCounter += num;
            }
            if (concept) {
              concept.value += 1;
              concept.confirmed += 1;
              concept.confirmedPax += bookingListing[i].accompaniedCount;
            }
            timeSlots.forEach((element: any) => {
              if (bookingListing[i].timeSlots?.includes(element.id)) {
                const matchingSlot = uniqueTimeSlotObjects?.find(
                  (item) => item.name === element.name
                );
                if (matchingSlot) {
                  let num = bookingListing[i].accompaniedCount;
                  matchingSlot.count = matchingSlot.count + 1;
                  matchingSlot.pax =
                    num !== null && num !== undefined
                      ? matchingSlot.pax + num
                      : matchingSlot.pax;
                  concept.slots.find(
                    (item: any) => item.name === matchingSlot?.name
                  ).pax += num;
                }
              }
            });
            break;
          }
          case "Reserved":
            reservedCounter = reservedCounter + 1;
            if (concept) {
              concept.value += 1;
              concept.reserved += 1;
            }

            break;
          case "No Show":
            noShowCounter = noShowCounter + 1;
            break;
          case "Waiting List":
            waitingListCounter = waitingListCounter + 1;
            if (concept) {
              concept.value += 1;
              concept.waitinglist += 1;
            }

            break;
          case "Cancelled": {
            cancelledCounter = cancelledCounter + 1;
            if (concept) {
              concept.value += 1;
              concept.cancelled += 1;
            }

            break;
          }
          case "Pending Review": {
            pendingReviewCounter = pendingReviewCounter + 1;
            if (concept) {
              concept.value += 1;
              concept.pendingReview += 1;
            }

            break;
          }
          case "Check In": {
            confirmedCounter = confirmedCounter + 1;
            SeatedCounter = SeatedCounter + 1;
            if (concept) {
              concept.value += 1;
              concept.checkedIn += 1;
            }

            timeSlots.forEach((element: any) => {
              if (bookingListing[i].timeSlots?.includes(element.id)) {
                const matchingSlot = uniqueTimeSlotObjects.find(
                  (item) => item.name === element.name
                );
                if (matchingSlot) {
                  let num = bookingListing[i].accompaniedCount;
                  matchingSlot.count = matchingSlot.count + 1;
                  matchingSlot.pax =
                    num !== null && num !== undefined
                      ? matchingSlot.pax + num
                      : matchingSlot.pax;
                  concept.slots.find(
                    (item: any) => item.name === matchingSlot?.name
                  ).pax += num;
                }
              }
            });
            break;
          }
        }
      }

      const timeSlotStatus = uniqueTimeSlotObjects.map((item: any) => {
        return {
          name: item.name,
          value: item.count,
          fill: "#F55992",
          data: {
            cancelled: cancelledCounter,
            seated: SeatedCounter,
            pax: item.pax,
          },
          type: "slot",
        };
      });

      const ConceptObjsTotal = [
        {
          id: 0,
          name: "All Venues",
          value:
            confirmedCounter +
            reservedCounter +
            SeatedCounter +
            waitingListCounter +
            pendingReviewCounter +
            cancelledCounter,
          confirmed: confirmedCounter,
          confirmedPax: confirmedPaxCounter,
          reserved: reservedCounter,
          pax: pax,
          waitinglist: waitingListCounter,
          cancelled: cancelledCounter,
          checkedIn: SeatedCounter,
          pendingReview: pendingReviewCounter,
          slots: uniqueTimeSlotObjects,
        },
        ...ConceptObjs,
      ];

      const statusListMobile = [
        {
          name: "Total Per Concept",
          value: 0,
          fill: "#555",
          data: ConceptObjsTotal,
          type: "perconcept",
        },
        {
          name: "Total",
          value: confirmedCounter + reservedCounter,
          fill: "#717781",
          data: {
            cancelled: cancelledCounter,
            seated: SeatedCounter,
            pax: pax,
          },
          type: "total",
        },
        {
          name: "Confirmed",
          value: confirmedCounter,
          fill: "#327AB1",
          data: { cancelled: cancelledCounter, seated: SeatedCounter },
          type: "confirmed",
        },
        {
          name: "Reserved",
          value: reservedCounter,
          fill: "#3C8C95",
          data: { cancelled: cancelledCounter, seated: SeatedCounter },
          type: "Reserved",
        },
        {
          name: "Waiting List",
          value: waitingListCounter,
          fill: "#F5A962",
          data: { cancelled: cancelledCounter, seated: SeatedCounter },
          type: "waiting list",
        },
        ...timeSlotStatus,
      ];

      dispatch(setListingStats(statusListMobile));
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function rushDays(listing: Booking[]) {
    try {
      let sundayCounter = 0;
      let mondayCounter = 0;
      let tuesdayCounter = 0;
      let wednesdayCounter = 0;
      let thursdayCounter = 0;
      let fridayCounter = 0;
      let saturdayCounter = 0;

      for (let i = 0; i < listing.length; i++) {
        let day = getDayName(listing[i].date!.split("T")[0], "en-EG");

        switch (day) {
          case "Sun":
            sundayCounter = sundayCounter + 1;
            break;
          case "Mon":
            mondayCounter = mondayCounter + 1;
            break;
          case "Tue":
            tuesdayCounter = tuesdayCounter + 1;
            break;
          case "Wed":
            wednesdayCounter = wednesdayCounter + 1;
            break;
          case "Thu":
            thursdayCounter = thursdayCounter + 1;
            break;
          case "Fri":
            fridayCounter = fridayCounter + 1;
            break;
          default:
            saturdayCounter = saturdayCounter + 1;
        }
      }

      const rushDays = [
        {
          name: "Sun",
          value: sundayCounter,
          fill: "#F9204A",
        },
        {
          name: "Mon",
          value: mondayCounter,
          fill: "#37B44E",
        },
        {
          name: "Tue",
          value: tuesdayCounter,
          fill: "#FF5215",
        },
        {
          name: "Wed",
          value: wednesdayCounter,
          fill: "#E65339",
        },
        {
          name: "Thu",
          value: thursdayCounter,
          fill: "#000000",
        },
        {
          name: "Fri",
          value: fridayCounter,
          fill: "#3C8C95",
        },
        {
          name: "Sat",
          value: saturdayCounter,
          fill: "#0088FE",
        },
      ];

      dispatch(setListingRushDays(rushDays));
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const booking: any = await API.graphql<GraphQLQuery<Booking>>({
        query: queries.getBooking,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return booking.data.getBooking;
    } catch (err) {
      throw err;
    }
  }

  async function getNotificationToken(params: GetVariables) {
    const { id } = params;

    try {
      const allUserPushTokens: any = await API.graphql<
        GraphQLQuery<UserPushToken>
      >({
        query: queries.getUserPushToken,
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      // const allUserPushTokens: UserPushToken[] = await DataStore.query(UserPushToken, u => u.userID('eq', id).reciveNotification('eq',true));
      // const tokens= allUserPushTokens
      // .filter(userPushToken => userPushToken.token !== null && userPushToken.token !== undefined)
      // .map(userPushToken => userPushToken.token!);

      const tokens = allUserPushTokens.data.getUserPushToken.token;
      return tokens;
    } catch (err) {
      throw err;
    }
  }

  async function getOnline(id: string, isAuth: boolean) {
    try {
      const booking: any = await API.graphql<GraphQLQuery<Booking>>({
        query: queries.getBooking,
        variables: { id },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return booking.data.getBooking;
    } catch (err) {
      throw err;
    }
  }

  /**
   * Create booking comments for the customer
   *
   * @param bookingID bookingID: string
   * @return void
   */
  const createBookingComments = async (params: BookingCommentsVariables) => {
    const { bookingID, comments, userID, userName } = params;

    if (comments.size > 0) {
      for (let comment of comments) {
        const createInput = {
          message: comment,
          comments: comments,
          bookingId: bookingID,
        };

        const commentParams: CreateVariables = {
          userID,
          userName,
          data: createInput,
        };

        await commentsCreate(commentParams);
      }
    }
  };

  /**
   * Create booking guests
   *
   * @param bookingID bookingID: string
   * @return void
   */
  const createBookingGuests = async (params: BookingGuestsVariables) => {
    const { bookingID, depositValue, guests, userID, userName } = params;

    if (guests.length > 0) {
      for (let guest of guests) {
        const createInput: CreateBookingGuestVariables = {
          guestName: guest,
          bookingID: bookingID,
          paidAmount: "0",
          paymentAmount: depositValue,
          paymentStatus: false,
          transactionID: "",
        };

        const bookingGuestParams: CreateVariables = {
          userID,
          userName,
          data: createInput,
        };

        await bookingGuestsCreate(bookingGuestParams);
      }
    }
  };

  /**
   * Update plan tables
   *
   * @param bookingID bookingID: string
   * @param newTables newTables: string[]
   * @param oldTables? oldTables?: string[]
   * @return void
   */
  const updatePlanTables = async (params: UpdatePlanTablesVariables) => {
    const { bookingID, newTables, oldTables } = params;
    // remove booking from old tables while (update booking)
    if (oldTables && oldTables.length > 0) {
      for (let table of oldTables) {
        const params: GetVariables = { id: table };
        const planTable = await planItemsGet(params);

        const newTableBookings: Set<any> = new Set(planTable.bookings);

        if (planTable.bookings && planTable.bookings.length > 0) {
          if (newTableBookings.has(bookingID)) {
            newTableBookings.delete(bookingID);
          }
        }

        const planItemParams: PlanItemUpdateVariables = {
          id: table,
          data: {
            bookings: Array.from(newTableBookings),
          },
        };

        await planItemsUpdate(planItemParams);
      }
    }
    // add booking to new tables (while create or update booking)
    if (newTables && newTables.length > 0) {
      for (let table of newTables) {
        const params: GetVariables = { id: table };
        const planTable = await planItemsGet(params);

        const newTableBookings: Set<any> = new Set(planTable.bookings);

        if (planTable.bookings && planTable.bookings.length > 0) {
          if (!newTableBookings.has(bookingID)) {
            newTableBookings.add(bookingID);
          }
        } else {
          newTableBookings.add(bookingID);
        }

        const planItemParams: PlanItemUpdateVariables = {
          id: table,
          data: {
            bookings: Array.from(newTableBookings),
          },
        };

        await planItemsUpdate(planItemParams);
      }
    }
  };

  /**
   * Update main guests flags
   *
   * @param mainGuestData mainGuestData: User
   * @return void
   */
  const updateCustomerFlags = async (params: UpdateCustomerFlagVariables) => {
    const {
      userID,
      accountID,
      flags,
      guestsListing,
      statusesListing,
      interestsListing,
      flagsListing,
      timeSlotsListing,
      timelinesListing,
    } = params;

    const updateInput = {
      userID: userID,
      accountID,
      flags: Array.from(flags),
      flagsListing,
      interestsListing,
      timeSlotsListing,
      statusesListing,
      timelinesListing,
    };

    await guestsUpdateOnline({
      id: userID,
      listing: guestsListing,
      data: updateInput,
    });
  };

  /**
   * Update customer stats
   *
   * @param mainGuestData mainGuestData: User
   * @return void
   */
  const updateCustomerStats = async (params: UpdateCustomerStatsVariables) => {
    const {
      mainGuestData,
      bookingID,
      accountID,
      guestsListing,
      statusesListing,
      interestsListing,
      flagsListing,
      timeSlotsListing,
      timelinesListing,
    } = params;

    if (mainGuestData.stats !== undefined && mainGuestData.stats !== null) {
      const oldStats: any[] = [...mainGuestData.stats];

      oldStats.push(bookingID);

      const updateInput = {
        accountID: accountID,
        stats: oldStats,
        flagsListing,
        interestsListing,
        timeSlotsListing,
        statusesListing,
        timelinesListing,
      };

      await guestsUpdateOnline({
        id: mainGuestData.id,
        listing: guestsListing,
        data: updateInput,
      });
    }
  };

  /**
   * Reset new booking fields
   */
  const reset = () => {
    dispatch(setMainGuest(""));
    dispatch(setGuestsCount(1));
    dispatch(setTimes(new Set()));
    dispatch(setTables(new Set()));
    dispatch(setFlags(new Set()));
    dispatch(setComments(new Set()));
  };

  async function updateTimeSlots(params: GetVariables) {
    const original = await get(params);

    if (original!.timeSlotID !== null && original!.timeSlots?.length === 0) {
      // Update Booking (Online)
      const updateInput: UpdateBookingInput = {
        id: original.id,
        timeSlots: [original!.timeSlotID!],
        _version: original._version,
      };

      const updated: any = await API.graphql<GraphQLQuery<Booking>>({
        query: updateBooking,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return updated;
    }

    return original;
  }

  async function updateMainGuest(params: GetVariables, mainGuest: string) {
    const original = await get(params);

    // Update Booking (Online)
    const updateInput: UpdateBookingInput = {
      id: original.id,
      mainGuest: mainGuest,
      _version: original._version,
    };

    const updated: any = await API.graphql<GraphQLQuery<Booking>>({
      query: updateBooking,
      variables: { input: updateInput },
      authMode: true
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.AWS_IAM,
    });

    return updated;
  }

  async function createNew(params: CreateBookingVariables) {
    console.log("createNew");

    const { userID, userName, data } = params;

    if (!data.status) {
      throw new Error("Cannot create bookings without status list");
    }

    const statusName: string = statusesGetName({
      id: data.status,
      listing: data.statusesListing,
    });

    if (data.timeSlots) {
      let timeSlots: string[] = Array.from(data.timeSlots);
      if (timeSlots?.length < 1) {
        throw new Error("Cannot create bookings without slots list");
      }
    }

    if (data.tables) {
      let tables: string[] = Array.from(data.tables);
      if (
        tables.length < 1 &&
        statusName !== "Waiting List" &&
        statusName !== "Pending Review"
      ) {
        throw new Error(
          `Reservation Table can not be empty with ${statusName} status`
        );
      }
    }

    if (!data.mainGuest || data.mainGuest === "") {
      throw new Error("Cannot create bookings without mainGuest list");
    }
    if (!data.conceptID || data.conceptID === "") {
      throw new Error("Cannot create bookings without conceptID");
    }
    if (!data.accountID || data.accountID === "") {
      throw new Error("Cannot create bookings without accountID");
    }
    if (!data.guestsCount || data.gestsCount === "") {
      throw new Error("Cannot create bookings without guestsCount");
    }

    if (!data.comments) {
      throw new Error("Cannot create bookings without comments list");
    }

    if (!data.flags || data.flags === "") {
      throw new Error("Cannot create bookings without flags list");
    }

    if (!data.date || data.date === "") {
      throw new Error("Cannot create bookings without date");
    }

    if (!data.guestsListing) {
      throw new Error("Cannot create bookings without users");
    }

    // creating New Booking
    try {
      //Check for duplicates
      if (statusName !== "Waiting List") {
        const params: DuplicatesParams = {
          conceptID: data.conceptID,
          bookingDate: getDateFormatted(data.date),
          timeSlots: Array.from(data.timeSlots),
          tables: data.tables.size === 0 ? [] : Array.from(data.tables),
        };

        const duplicates: boolean = await isExists(params);

        if (duplicates) {
          throw new Error(
            "Table is already booked in this time slot and booking date"
          );
        }
      }

      // Calculate time left for payment, Example: 10:00:45 PM
      const currentTime = new Date(); // Get the current date and time
      const formattedTime = currentTime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });

      const createInput: CreateBookingInput = {
        conceptID: data.conceptID,
        date: getDateFormatted(data.date),
        timeSlots: Array.from(data.timeSlots),
        mainGuest: data.mainGuest,
        childCount: parseInt(data.childCount),
        accompaniedCount:
          data.eventGuests && data.eventGuests.length > 0
            ? data.eventGuests.length
            : parseInt(data.guestsCount),
        statusID: data.status,
        tables: data.tables.size === 0 ? [] : Array.from(data.tables),
        customerName: data.mainGuestData.name.toLowerCase(),
        customerPhone: data.mainGuestData.phone_number,
        customerGroup: data.customerGroup.toLowerCase(),
        tableName: data.tableName,
        currency: data.currency,
        channel: Channels.WEB_CHANNEL,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
        comments: Array.from(data.comments),
        eventName: data.eventName,
        eventGuests: data.eventGuests,
        depositStatus: data.depositStatus,
        depositValue: data.depositValue.toString(),
        valetDeposit: parseFloat(data.valetDeposit),
        childDeposit: parseFloat(data.childDeposit),
        disablePartialPayment: data.disablePartialPayment,
        disablePayment: data.disablePayment,
        timeLeft: formattedTime,
      };

      const model: Booking = await DataStore.save(
        new Booking(createInput as any)
      );

      // Create booking comments
      const commentsList: string[] = Array.from(data.comments);
      const bookingCommentParams: BookingCommentsVariables = {
        bookingID: model.id,
        comments: data.comments,
        commentsList: commentsList,
        userID: userID,
        userName: userName,
      };
      await createBookingComments(bookingCommentParams);

      // Create booking guests
      const bookingGuestParams: BookingGuestsVariables = {
        bookingID: model.id,
        depositValue: data.depositValue,
        guests: data.eventGuests,
        userID: userID,
        userName: userName,
      };

      await createBookingGuests(bookingGuestParams);

      const customerStatusParams: UpdateCustomerStatsVariables = {
        mainGuestData: data.mainGuestData,
        bookingID: model.id,
        accountID: data.accountID,
        guestsListing: data.guestsListing,
        statusesListing: data.statusesListing,
        interestsListing: data.interestsListing,
        flagsListing: data.flagsListing,
        timeSlotsListing: data.timeSlotsListing,
        timelinesListing: data.timelinesListing,
      };
      await updateCustomerStats(customerStatusParams);
      // await sendReservationMail();

      const updatePlanTablesParams: UpdatePlanTablesVariables = {
        bookingID: model.id,
        newTables: createInput.tables as any,
      };
      await updatePlanTables(updatePlanTablesParams);

      // Send SMS/Email Notification
      const guest = await guestsGet({ id: data.mainGuest });
      const notificationsParams: NotificationAutoSendVariables = {
        booking: model, // new booking object
        guest: guest,
        conceptID: data.conceptID,
        statusesListing: data.statusesListing,
      };
      if (statusName !== "Waiting List") {
        await notificationsCheckAutoSendStatus(notificationsParams);
      }
      reset();
      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      throw err;
    }
  }

  async function updateStatus(params: BookingUpdateVariables) {
    console.log("Update status");

    const { id, data } = params;

    if (!data.statusesListing) {
      throw new Error(`Cannot update ${singleName} without statuses list`);
    }
    if (!data.planItems) {
      throw new Error(`Cannot update ${singleName} without planItems list`);
    }
    if (!data.userID) {
      throw new Error(`Cannot update ${singleName} without userID`);
    }
    if (!data.userName) {
      throw new Error(`Cannot update ${singleName} without userName`);
    }
    if (!data.guestsListing) {
      throw new Error(`Cannot update ${singleName} without guestsListing`);
    }
    if (!data.accountID) {
      throw new Error(`Cannot update ${singleName} without accountID`);
    }

    try {
      const original = await get({ id });

      let mainGuest = original!.mainGuest;
      let statusID = original!.statusID;
      let newTables = original!.tables;
      let tableName = original!.tableName;
      let oldTables: any[] = [];

      if (original && original.tables) oldTables = original!.tables;
      if (data.mainGuest) mainGuest = data.mainGuest;

      let statusName = "";
      if (data.statusID) {
        statusID = data.statusID;

        if (data.statusID !== original!.statusID) {
          statusName = statusesGetName({
            id: statusID,
            listing: data.statusesListing,
          });

          // Release Table
          if (
            statusName.toLowerCase() === "cancelled" ||
            statusName.toLowerCase() === "closed" ||
            statusName.toLowerCase() === "no show"
          ) {
            newTables = [];
            tableName = "";

            if (original!.tables) {
              const releaseParams: ReleaseTablesVariables = {
                bookingID: id,
                tables: Array.from(original!.tables),
              };

              releaseTables(releaseParams);
            }
          }
        }
      }

      // Update Booking (Online)
      const updateInput: UpdateBookingInput = {
        id: original.id,
        date: getDateFormatted(data.date),
        timeSlotID: original!.timeSlotID,
        mainGuest: mainGuest,
        childCount: original!.childCount,
        accompaniedCount: original!.accompaniedCount,
        statusID: statusID,
        tables: newTables ? newTables : [],
        customerName: data.customerName
          ? data.customerName.toLowerCase()
          : original!.customerName,
        customerPhone: data.customerPhone
          ? data.customerPhone
          : original!.customerPhone,
        customerGroup: data.customerGroup
          ? data.customerGroup.toLowerCase()
          : original!.customerGroup,
        tableName: tableName,
        lastComment: original!.lastComment,
        commentCreatedBy: original!.commentCreatedBy,
        commentCreatedDate: original!.commentCreatedDate,
        isConfirmedByUser: original!.isConfirmedByUser,
        _version: original._version,
      };

      const updated: any = await API.graphql<GraphQLQuery<Booking>>({
        query: updateBooking,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      if (data.statusID && data.statusID !== original!.statusID) {
        // Send SMS/Email Notification
        const guest = await guestsGet({
          id: updated.data.updateBooking.mainGuest,
        });
        const notificationsParams: NotificationAutoSendVariables = {
          booking: updated.data.updateBooking,
          guest: guest,
          conceptID: conceptsSelected,
          statusesListing: data.statusesListing,
        };
        if (
          statusName !== "" &&
          statusName.toLowerCase() !== "Waiting List" &&
          // statusName.toLowerCase() !== "cancelled" &&
          statusName.toLowerCase() !== "closed"
        ) {
          await notificationsCheckAutoSendStatus(notificationsParams);
        }
      }

      if (data.tables) {
        const updatePlanTablesParams: UpdatePlanTablesVariables = {
          bookingID: id,
          newTables: Array.from(data.tables),
          oldTables,
        };
        await updatePlanTables(updatePlanTablesParams);
      }

      const createInput = {
        ...data,
        tableName,
        date: getDateFormatted(data.date),
        bookingId: id,
        resource: original!,
        statusesListing: data.statusesListing,
        timeSlotsListing: data.timeSlotsListing,
        timelinesListing: data.timelinesListing,
      };

      const timelineParams: CreateVariables = {
        userID: data.userID,
        userName: data.userName,
        data: createInput,
      };

      await timelinesCreate(timelineParams);

      dispatch(setSelected(updated));

      showConfirm(`${singleName} has been updated successfully`);
      return updated;
    } catch (err) {
      throw err;
    }
  }

  const releaseTables = async (params: ReleaseTablesVariables) => {
    const { bookingID, tables } = params;

    for (let table of tables) {
      const newTableBookings: string[] = [];

      let planItemObj = await planItemsGet({ id: table });

      if (planItemObj.bookings) {
        for (let booking of planItemObj.bookings!) {
          if (booking !== bookingID) newTableBookings.push(booking!);
        }
      }

      if (!table) {
        throw new Error("plan item is required");
      }

      const planItemParams: PlanItemUpdateVariables = {
        id: table,
        data: { bookings: newTableBookings },
      };

      planItemsUpdate(planItemParams);
    }
  };

  async function update(params: BookingUpdateVariables) {
    const { id, data, guestSelected } = params;

    const statusName: string = statusesGetName({
      id: data.statusID,
      listing: data.statusesListing,
    });

    if (
      data.times &&
      data.times.size === 0 &&
      statusName.toLowerCase() !== "waiting list"
    ) {
      throw new Error(`Cannot update booking status without TimeSLot`);
    }

    if (!data.groupsListing) {
      throw new Error(`Cannot update ${singleName} without groups list`);
    }

    if (!data.times || data.times.size === 0) {
      throw new Error("Cannot update bookings without times list");
    }

    if (!data.statusesListing) {
      throw new Error(`Cannot update ${singleName} without statuses list`);
    }

    if (!data.planItems) {
      throw new Error(`Cannot update ${singleName} without planItems list`);
    }

    if (!data.userID) {
      throw new Error(`Cannot update ${singleName} without userID`);
    }

    if (!data.userName) {
      throw new Error(`Cannot update ${singleName} without userName`);
    }

    if (!data.guestsListing) {
      throw new Error(`Cannot update ${singleName} without guestsListing`);
    }

    if (!data.timeSlotsListing) {
      throw new Error(`Cannot update ${singleName} without timeSlotsListing`);
    }

    if (!data.flags) {
      throw new Error(`Cannot update ${singleName} without flags`);
    }

    if (!data.interestsListing) {
      throw new Error(`Cannot update ${singleName} without interestsListing`);
    }

    if (!data.accountID) {
      throw new Error(`Cannot update ${singleName} without accountID`);
    }

    try {
      const original = await get({ id });

      let customerPhone = original!.customerPhone;
      let timeSlots = original!.timeSlots;
      let mainGuest = original!.mainGuest;
      let childCount = original!.childCount ? original!.childCount : 0;
      let accompaniedCount = original!.accompaniedCount
        ? original!.accompaniedCount
        : 0;
      let statusID = original!.statusID;
      let tableName = original!.tableName;
      let lastComment = original!.lastComment;
      let comments = original!.comments;
      let commentCreatedBy = original!.commentCreatedBy;
      let commentCreatedDate = original!.commentCreatedDate;
      let newTables = original!.tables;
      let isConfirmedByUser = original!.isConfirmedByUser;
      let oldTables: any[] = [];
      // let disablePartialPayment = original!.disablePartialPayment;
      // let disablePayment = original!.disablePayment;
      if (original && original.tables) oldTables = original!.tables;

      if (data.times) timeSlots = Array.from(data.times);
      if (data.customerPhone) customerPhone = data.customerPhone;
      if (data.mainGuest) mainGuest = data.mainGuest;

      // check if dates are change
      const params: DuplicatesParams = {
        conceptID: data.conceptID,
        bookingDate: getDateFormatted(data.date),
        timeSlots: Array.from(data.times),
        tables: data.tables?.size ? Array.from(data.tables) : [],
        bookingID: id,
      };

      if (getDateFormatted(data.date) !== original!.date) {
        const duplicates: boolean = await isExists(params);

        if (duplicates) {
          throw new Error("booking already exists for the same date and time");
        }
      }

      if (
        data.tables &&
        !isEquivalentArrays(
          Array.from(data.tables),
          original!.tables as string[]
        )
      ) {
        newTables = Array.from(data.tables);
        tableName = await planItemsGetNames({
          listing: data.planItems,
          tables: data.tables,
        });

        if (data.tables.size > 0) {
          const duplicates: boolean = await isExists(params);

          if (duplicates) {
            throw new Error("The new selected tables are already booked");
          }
        }
      }

      if (data.statusID) {
        statusID = data.statusID;
      }

      if (
        statusName.toLowerCase() === "cancelled" ||
        statusName.toLowerCase() === "closed" ||
        statusName.toLowerCase() === "waiting list" || // Need to know why
        statusName.toLowerCase() === "no show"
      ) {
        newTables = [];
        tableName = "";

        if (original!.tables) {
          const releaseParams: ReleaseTablesVariables = {
            bookingID: id,
            tables: Array.from(original!.tables),
          };

          await releaseTables(releaseParams);
        }
      } else if (Array.from(data.tables).length < 1) {
        throw new Error(
          `Reservation Table is required with ${statusName} status`
        );
      }

      if (data.comments && data.comments.length > 0) {
        const commentsList: string[] = Array.from(data.comments);
        lastComment = commentsList[0];
        comments = Array.from(data.comments);
        commentCreatedBy = data.userName;
        commentCreatedDate = getDateFormatted(new Date());
      }

      if (data.comments && data.comments.length < 1) {
        lastComment = "";
        comments = [];
      }

      if (data.isConfirmedByUser) {
        isConfirmedByUser = data.isConfirmedByUser;
        statusID = data.statusesListing.filter(
          (status: any) =>
            statusesGetName({
              id: status.id,
              listing: data.statusesListing,
            }).toLowerCase() === "confirmed"
        )[0].id;
      }

      if (
        !isEquivalentArrays(
          timeSlots as string[],
          original!.timeSlots as string[]
        )
      ) {
        if (original!.tables) {
          const newTimeSlots: string[] = [];

          for (let timeSlot of data.times) {
            if (
              original!.timeSlots &&
              original!.timeSlots.indexOf(timeSlot) === -1
            ) {
              if (timeSlot) newTimeSlots.push(timeSlot);
            }
          }

          if (newTimeSlots.length > 0) {
            const params: DuplicatesParams = {
              conceptID: data.conceptID,
              bookingDate: getDateFormatted(data.date),
              timeSlots: newTimeSlots,
              tables: data.tables.size === 0 ? [] : Array.from(data.tables),
            };
            const duplicates: boolean = await isExists(params);

            if (duplicates) {
              throw new Error("Table already reserved in the new time slot");
            }
          }
        }
      }

      if (customerPhone && !validatePhone(customerPhone)) {
        throw new Error("Phone number is not valid");
      }

      // Booking guest list part
      const newGuestList = original!.eventGuests ? original!.eventGuests : [];
      if (data.eventGuests && data.eventGuests.length > 0) {
        newGuestList?.push(data.eventGuests);
      }

      if (data.accompaniedCount) {
        if (data.accompaniedCount === "0") throw new Error("Pax Can't be 0");
        accompaniedCount =
          typeof data.accompaniedCount === "string"
            ? parseInt(data.accompaniedCount)
            : data.accompaniedCount;
      }

      if (newGuestList.length > 0 && accompaniedCount !== newGuestList.length) {
        accompaniedCount = newGuestList.length;
      }

      // Update guest list deposit value and check availability
      if (data.depositValue) {
        const updateStatus = await bookingGuestsUpdateDepositValue(
          original!.id,
          data.depositValue
        );

        if (!updateStatus) {
          throw new Error(
            "You can't change the deposit value with a lower amount than what was paid."
          );
        }
      }

      // Update Booking (Online)
      const updateInput: UpdateBookingInput = {
        id: original.id,

        date: data.date ? getDateFormatted(data.date) : original!.date,
        timeSlots: Array.from(data.times),
        mainGuest: mainGuest,
        childCount: data.childCount ? data.childCount : childCount,
        accompaniedCount:
          data.eventGuests && data.eventGuests.length > 0
            ? data.eventGuests.length
            : accompaniedCount,

        statusID: statusID,
        tables: newTables ? newTables : [],
        comments: comments,
        customerName: data.customerName
          ? data.customerName.toLowerCase()
          : original!.customerName,
        customerPhone: data.customerPhone
          ? data.customerPhone
          : original!.customerPhone,
        customerGroup: data.customerGroup
          ? data.customerGroup.toLowerCase()
          : original!.customerGroup,
        tableName: tableName,
        lastComment: lastComment,
        commentCreatedBy: commentCreatedBy,
        commentCreatedDate: commentCreatedDate,
        isConfirmedByUser: isConfirmedByUser,
        depositStatus: data.depositStatus,
        depositValue: data.depositValue,
        childDeposit: parseFloat(data.childDeposit),
        valetDeposit: parseFloat(data.valetDeposit),
        eventName: data.eventName ? data.eventName : original!.eventName,
        disablePartialPayment: data.disablePartialPayment,
        disablePayment: data.disablePayment,
        eventGuests: newGuestList,
        timeLeft: data.timeLeft,

        _version: original._version,
      };

      const updated: any = await API.graphql<GraphQLQuery<Booking>>({
        query: updateBooking,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      // Send SMS/Email Notification
      if (data.statusID !== original!.statusID) {
        const guest = await guestsGet({
          id: updated.data.updateBooking.mainGuest,
        });
        const notificationsParams: NotificationAutoSendVariables = {
          booking: updated.data.updateBooking,
          guest: guest,
          conceptID: conceptsSelected,
          statusesListing: data.statusesListing,
        };
        if (statusName !== "Waiting List") {
          await notificationsCheckAutoSendStatus(notificationsParams);
        }
      }

      if (data.tables) {
        const updatePlanTablesParams: UpdatePlanTablesVariables = {
          bookingID: id,
          newTables: Array.from(data.tables),
          oldTables,
        };
        await updatePlanTables(updatePlanTablesParams);
      }

      const flagsParams: UpdateCustomerFlagVariables = {
        userID: data.mainGuest,
        accountID: data.accountID,
        flags: data.flags,
        guestsListing: data.guestsListing,
        statusesListing: data.statusesListing,
        interestsListing: data.interestsListing,
        flagsListing: data.flagsListing,
        timeSlotsListing: data.timeSlotsListing,
        timelinesListing: data.timelinesListing,
      };

      await updateCustomerFlags(flagsParams);

      const createInput = {
        ...data,
        tableName,
        date: getDateFormatted(data.date),
        bookingId: id,
        resource: original!,
        statusesListing: data.statusesListing,
        timeSlotsListing: data.timeSlotsListing,
        timelinesListing: data.timelinesListing,
      };

      const timelineParams: CreateVariables = {
        userID: data.userID,
        userName: data.userName,
        data: createInput,
      };

      await timelinesCreate(timelineParams);

      dispatch(setSelected(updated));

      showConfirm(`${singleName} has been updated successfully`);
      return updated;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async function statusUpdate(params: BookingUpdateVariables) {
    console.log("statusUpdate");
    const { id, data } = params;

    if (!data.statusesListing) {
      throw new Error(`Cannot update ${singleName} without statuses list`);
    }

    if (!data.timeSlotsListing) {
      throw new Error(`Cannot update ${singleName} without slots list`);
    }

    if (!data.timelinesListing) {
      throw new Error(`Cannot update ${singleName} without timelines list`);
    }
    try {
      const original = await get({ id });

      let statusID = original!.statusID;
      let tableName = original!.tableName;
      let newTables = original!.tables;

      if (data.statusID !== original!.statusID) {
        statusID = data.statusID;
        const statusName = statusesGetName({
          id: statusID,
          listing: data.statusesListing,
        });

        if (
          statusName.toLowerCase() === "cancelled" ||
          statusName.toLowerCase() === "closed" ||
          statusName.toLowerCase() === "no show"
        ) {
          newTables = [];
          tableName = "";

          if (original!.tables) {
            const releaseParams: ReleaseTablesVariables = {
              bookingID: id,
              tables: Array.from(original!.tables),
            };

            await releaseTables(releaseParams);
          }
        }
      }
      const currentTime = new Date();
      const formattedTime = currentTime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });
      const statusName = statusesGetName({
        id: statusID,
        listing: data.statusesListing,
      });

      // Update Booking (Online)
      const updateInput: UpdateBookingInput = {
        id: original.id,

        statusID: statusID,
        tables: newTables,
        tableName: tableName,
        timeLeft: statusName.toLowerCase() === "reserved" ? formattedTime : "",

        _version: original._version,
      };

      const updated: any = await API.graphql<GraphQLQuery<Booking>>({
        query: updateBooking,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      if (data.statusID && data.statusID !== original!.statusID) {
        // Send SMS/Email Notification
        const guest = await guestsGet({
          id: updated.data.updateBooking.mainGuest,
        });
        const notificationsParams: NotificationAutoSendVariables = {
          booking: updated.data.updateBooking,
          guest: guest,
          conceptID: conceptsSelected,
          statusesListing: data.statusesListing,
        };
        if (
          statusName !== "" &&
          statusName.toLowerCase() !== "Waiting List" &&
          // statusName.toLowerCase() !== "cancelled" &&
          statusName.toLowerCase() !== "closed"
        ) {
          await notificationsCheckAutoSendStatus(notificationsParams);
        }
      }

      const createInput = {
        ...data,
        tableName,
        bookingId: id,
        resource: original!,
        statusesListing: data.statusesListing,
        timeSlotsListing: data.timeSlotsListing,
        timelinesListing: data.timelinesListing,
      };

      const timelineParams: CreateVariables = {
        userID: data.userID,
        userName: data.userName,
        data: createInput,
      };

      await timelinesCreate(timelineParams);

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      throw err;
    }
  }

  async function statusUpdateOnline(params: any) {
    const { id, data, paymentObj } = params;

    if (!data.original) {
      throw new Error(
        `Cannot update ${singleName} online without the resource`
      );
    }

    if (!data.statusesListing) {
      throw new Error(`Cannot update ${singleName} without statuses list`);
    }

    try {
      let statusID = data.original.statusID;
      let tableName = data.original.tableName;
      let newTables = data.original.tables;

      if (data.statusID !== data.original.statusID) {
        statusID = data.statusID;
        const statusName = statusesGetName({
          id: statusID,
          listing: data.statusesListing,
        });

        if (
          statusName.toLowerCase() === "cancelled" ||
          statusName.toLowerCase() === "closed" ||
          statusName.toLowerCase() === "no show"
        ) {
          newTables = [];
          tableName = "";

          if (data.original.tables) {
            const releaseParams: ReleaseTablesVariables = {
              bookingID: id,
              tables: Array.from(data.original.tables),
            };

            await releaseTables(releaseParams);
          }
        }

        const updateInput: any = { id };

        updateInput.statusID = statusID;
        updateInput.tables = newTables;
        updateInput.tableName = tableName;
        updateInput.isConfirmedByUser = true;
        updateInput._version = data.original._version;
        if (paymentObj) {
          updateInput.paymentValue = paymentObj.paymentValue;
          updateInput.paymentStatus = paymentObj.paymentStatus;
        }

        const updated: any = await API.graphql({
          query: updateBooking,
          variables: { input: updateInput },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        return updated.data.updateBooking;
      } else if (paymentObj) {
        const updateInput: any = { id };

        updateInput.statusID = statusID;
        updateInput.tables = newTables;
        updateInput.tableName = tableName;
        updateInput.isConfirmedByUser = true;
        updateInput._version = data.original._version;
        if (paymentObj) {
          updateInput.paymentValue = paymentObj.paymentValue;
          updateInput.paymentStatus = paymentObj.paymentStatus;
        }

        const updated: any = await API.graphql({
          query: updateBooking,
          variables: { input: updateInput },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        return updated.data.updateBooking;
      }
    } catch (err) {
      throw err;
    }
  }

  async function updatesUser(params: BookingUpdateVariables) {
    const { id, data } = params;
    try {
      const original = await get({ id });

      // Update Booking (Online)
      const updateInput: UpdateBookingInput = {
        id: original.id,

        customerName: data.customerName,
        customerPhone: data.customerPhone,

        _version: original._version,
      };

      const updated = await API.graphql<GraphQLQuery<Booking>>({
        query: updateBooking,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      // const updated = await DataStore.save(
      //   Booking.copyOf(original!, (updated) => {
      //     updated.customerName = data.customerName;
      //     updated.customerPhone = data.customerPhone;
      //   })
      // );

      dispatch(setSelected(updated));

      return updated;
    } catch (err) {
      throw err;
    }
  }

  async function updateOnline(booking: any, data: any) {
    if (!data.statusesListing) {
      throw new Error(`Cannot update ${singleName} without statuses list`);
    }
    try {
      if (data.isConfirmedByUser) {
        let isConfirmedByUser = data.isConfirmedByUser;
        let statusID = data.statusesListing.filter(
          (status: any) =>
            statusesGetName({
              id: status.id,
              listing: data.statusesListing,
            }).toLowerCase() === "confirmed"
        )[0].id;

        const updated: GraphQLResult<any> = await API.graphql({
          query: updateBooking,
          variables: {
            input: {
              id: booking.id,
              statusID,
              isConfirmedByUser,
              _version: booking._version,
            },
          },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        if (updated && updated.data) {
          dispatch(setSelected(updated.data.updateBooking));
          return updated.data.updateBooking;
        }
      }
    } catch (err) {
      throw err;
    }
  }

  async function swapOffline(original: any, data: any) {
    try {
      // Update Booking (Online)
      const updateInput: UpdateBookingInput = {
        id: original.id,

        tables: data.data.tables,
        tableName: data.data.tableName,

        _version: original._version,
      };

      const updated = await API.graphql<GraphQLQuery<Booking>>({
        query: updateBooking,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      // const updated = await DataStore.save(
      //   Booking.copyOf(original!, (updated) => {
      //     updated.tables = data.data.tables;
      //     updated.tableName = data.data.tableName;
      //   })
      // );

      showConfirm(`${singleName} swapped successfully`);
      return updated;
    } catch (err) {
      throw err;
    }
  }

  async function swapTwoBookings(bookings: any[]) {
    if (bookings && bookings.length > 2) {
      throw new Error("You can only swap two bookings at a time");
    }
    if (bookings && bookings.length < 2) {
      throw new Error("You need to select two bookings to swap");
    }

    try {
      const firstBooking: any = await get({ id: bookings[0] });
      const secondBooking: any = await get({ id: bookings[1] });
      if (
        !firstBooking ||
        !secondBooking ||
        firstBooking?.tables?.length === 0 ||
        secondBooking?.tables?.length === 0
      ) {
        throw new Error("You can't swap any booking without tables");
      }

      if (firstBooking && secondBooking) {
        const firstUpdateParams: BookingUpdateVariables = {
          id: firstBooking.id,
          data: {
            tables: firstBooking.tables,
            tableName: firstBooking.tableName,
          },
        };

        const secondUpdateParams: BookingUpdateVariables = {
          id: secondBooking.id,
          data: {
            tables: secondBooking.tables,
            tableName: secondBooking.tableName,
          },
        };

        await Promise.all([
          swapOffline(firstBooking, secondUpdateParams),
          swapOffline(secondBooking, firstUpdateParams),
        ]);
      }
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Booking.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      throw err;
    }
  }

  async function updateComment(id: string, comments: string[]) {
    try {
      const original = await get({ id });

      // Update Booking (Online)
      const updateInput: UpdateBookingInput = {
        id: original.id,

        comments: comments,

        _version: original._version,
      };

      await API.graphql<GraphQLQuery<Booking>>({
        query: updateBooking,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      // await DataStore.save(
      //   Booking.copyOf(original!, (updated) => {
      //     updated.comments = comments;
      //   })
      // );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      throw err;
    }
  }

  /*
   * This function update booking guest list
   * Also change booking status and payment status
   */
  async function updateEventGuests(id: string, guests: string[], data: any) {
    try {
      const original = await get({ id });

      let updateStatus = false;
      const Reserved = statusesListing.find((s: any) => s.name === "Reserved");
      const Confirmed = statusesListing.find(
        (s: any) => s.name === "Confirmed"
      );
      if (original && original!.statusID === Confirmed.id) {
        updateStatus = true;
      }

      // const updatedBooking = Booking.copyOf(original!, (updated) => {
      //   updated.eventGuests = guests;
      //   updated.accompaniedCount = guests.length > 0 ? guests.length : 1;

      //   updated.paymentStatus = updateStatus ? false : original?.paymentStatus;
      //   updated.statusID =
      //     updateStatus && original?.depositStatus
      //       ? Reserved.id
      //       : original?.statusID;
      // });

      // await DataStore.save(updatedBooking);

      const updateInput: UpdateBookingInput = {
        id: original.id,

        eventGuests: guests,
        accompaniedCount: guests.length > 0 ? guests.length : 1,
        paymentStatus: updateStatus ? false : original?.paymentStatus,
        statusID:
          updateStatus && original?.depositStatus
            ? Reserved.id
            : original?.statusID,

        // Update booking with new form data
        // depositStatus:
        //   data && data.depositStatus
        //     ? data.depositStatus
        //     : original?.depositStatus,
        // depositValue:
        //   data && data.depositValue
        //     ? data.depositValue
        //     : original?.depositValue,

        _version: original._version,
      };

      await API.graphql<GraphQLQuery<Booking>>({
        query: updateBooking,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      throw err;
    }
  }

  async function bulkTrash(params: BookingBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: BookingGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function updateStatusesForExport(booking: any, statuses: any) {
    let confirmed: ReservationStatus | undefined = statuses.find(
      (status: ReservationStatus) => status.name.toLowerCase() === "confirmed"
    );
    let cancelled: ReservationStatus | undefined = statuses.find(
      (status: ReservationStatus) => status.name.toLowerCase() === "cancelled"
    );
    let reserved: ReservationStatus | undefined = statuses.find(
      (status: ReservationStatus) => status.name.toLowerCase() === "reserved"
    );
    if (booking.statusID === reserved?.id) {
      const bookingParam: any = {
        id: booking.id,
        data: {
          statusID: booking.paymentStatus ? confirmed?.id : cancelled?.id,
          original: booking,
          statusesListing: statuses,
        },
      };
      await statusUpdateOnline(bookingParam);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "timeSlotID",
      numeric: false,
      disablePadding: false,
      label: "Time Slot",
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "customerGroup",
      numeric: false,
      disablePadding: false,
      label: "Group",
    },
    {
      id: "customerPhone",
      numeric: false,
      disablePadding: false,
      label: "Mobile",
    },
    {
      id: "accompaniedCount",
      numeric: false,
      disablePadding: false,
      label: "NO of Guests",
    },
    // {/* Children */}
    {
      id: "childCount",
      numeric: false,
      disablePadding: false,
      label: "NO of Children",
    },
    {
      id: "tableName",
      numeric: false,
      disablePadding: false,
      label: "Table",
    },
    {
      id: "statusID",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    // {
    //   id: "date",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Booking Date",
    // },
    {
      id: "paymentStatus",
      numeric: false,
      disablePadding: false,
      label: "Payment Status",
    },
    {
      id: "paymentValue",
      numeric: false,
      disablePadding: false,
      label: "Payment Value",
    },
    // {
    //   id: "valetDeposit",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Valet Deposit",
    // },
    {
      id: "paymentLink",
      numeric: false,
      disablePadding: false,
      label: "Pay Link",
    },
    {
      id: "channel",
      numeric: false,
      disablePadding: false,
      label: "Channel",
    },
    {
      id: "lastComment",
      numeric: false,
      disablePadding: false,
      label: "Comment",
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created At",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = [];

  const api: any = {};

  api[`${listingName}Model`] = Booking as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchOnline`] = fetchOnline;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchAllByBookingDate`] = fetchByBookingDate;
  api[`${listingName}FetchAllBookingPerWeek`] = fetchBookingPerWeek;
  api[`${listingName}FetchStatistics`] = statistics;
  api[`${listingName}FetchRushDays`] = rushDays;
  api[`${listingName}FetchByConcept`] = fetchByConcept;

  api[`${listingName}FetchChannels`] = channels;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}CreateNew`] = createNew;
  api[`${listingName}UpdateStatus`] = updateStatus;
  api[`${listingName}Update`] = update;
  api[`${listingName}IsExists`] = isExists;
  api[`${listingName}UpdateOnline`] = updateOnline;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Reset`] = reset;
  api[`${listingName}Swap`] = swapTwoBookings;
  api[`syncUserGroup`] = syncUserGroup;
  api[`${listingName}UpdateTimeSlots`] = updateTimeSlots;
  api[`${listingName}UpdateMainGuest`] = updateMainGuest;
  api[`${listingName}UpdateComment`] = updateComment;
  api[`${listingName}UpdateEventGuests`] = updateEventGuests;
  api[`${listingName}StatusUpdate`] = statusUpdate;
  api[`${listingName}StatusUpdateOnline`] = statusUpdateOnline;
  api[`${listingName}UpdateStatusesForExport`] = updateStatusesForExport;
  api[`${listingName}GetNotificationToken`] = getNotificationToken;
  api[`${listingName}UpdatesUser`] = updatesUser;
  api[`${listingName}ChangeListing`] = (listing: Booking[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangePendingListing`] = (listing: Booking[]) =>
    dispatch(setPendingListing(listing));
  api[`${listingName}ChangeSelected`] = (model: any) =>
    dispatch(setSelected(model));
  api[`${listingName}ChangeDate`] = (date: any) => {
    dispatch(setDate(date));
    localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE, getDateFormatted(date));
  };
  api[`${listingName}ChangeStatus`] = (status: string) =>
    dispatch(setStatus(status));
  api[`${listingName}ChangeTimes`] = (times: any) => dispatch(setTimes(times));
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ExportWithGuest`] = exportWithGuestList;
  api[`${listingName}ChangeFlags`] = (flags: any) => dispatch(setFlags(flags));
  api[`${listingName}ChangeTables`] = (tables: any) =>
    dispatch(setTables(tables));
  api[`${listingName}ChangeComments`] = (comments: any) =>
    dispatch(setComments(comments));
  api[`${listingName}ChangeGuestsCount`] = (number: number) =>
    dispatch(setGuestsCount(number));
  api[`${listingName}ChangeMainGuest`] = (id: string) =>
    dispatch(setMainGuest(id));
  api[`${listingName}ChangeSlots`] = (listing: Booking[]) =>
    dispatch(setListingSlots(listing));
  api[`${listingName}ChangeChannels`] = (listing: Booking[]) =>
    dispatch(setListingChannels(listing));
  api[`${listingName}ChangeSlotsPrev`] = (listing: Booking[]) =>
    dispatch(setListingSlotsPrev(listing));
  api[`${listingName}ChangeSlotsNext`] = (listing: Booking[]) =>
    dispatch(setListingSlotsNext(listing));

  return api;
};

export default useResource;
