import {
  CreateVariables,
  TimelineBulkTrashVariables,
  TimelineUpdateVariables,
  Option,
  CreateOnlineVariables,
} from "./../models/app";
import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setListing, setSelected } from "../store/ducks/timeline";
import { HeadCell } from "../models/dataTable";
import { Timeline } from "../models";
import { BookingTimelineActions, TimelineActions } from "../constants/enums";
import { isEquivalentArrays, mergeListOfStringsByDash } from "../helpers/utils";
import useApp from "./useApp";
import useStatus from "./useStatus";
import useTimeSlot from "./useTimeSlot";
import { TimelineGetVariables, TimelineListingVariables } from "../models/app";
import { CreateTimelineInput } from "../models/GQL_API";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { createTimeline } from "../graphql/mutations";
const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const { statusesGetName } = useStatus("statuses", "status");
  const { timeSlotsGetNames } = useTimeSlot("timeSlots", "timeSlot");

  async function fetch(props: TimelineListingVariables) {
    const { startIndex, limit, bookingId, customerId } = props;

    try {
      const listing = await DataStore.query(
        Timeline as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (bookingId) model.bookingId("eq", bookingId);
          if (customerId) model.customerId("eq", customerId);

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function get(params: TimelineGetVariables) {
    const { id, listing } = params;

    try {
      const single: Timeline | undefined = await DataStore.query(
        Timeline as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.resource) {
      const error = new Error(`Cannot create ${singleName} without resource`);
      return showError(error);
    }
    if (!data.statusesListing) {
      const error = new Error(
        `Cannot create ${singleName} without statusesListing`
      );
      return showError(error);
    }
    if (!data.timeSlotsListing) {
      const error = new Error(
        `Cannot create ${singleName} without timeSlotsListing`
      );
      return showError(error);
    }

    if (data.bookingId) {
      if (
        data.accompaniedCount &&
        +data.accompaniedCount !== data.resource.accompaniedCount
      ) {
        const actionName = BookingTimelineActions.GUEST_COUNT;
        const oldData = data.resource.accompaniedCount
          ? data.resource.accompaniedCount.toString()
          : "";
        const newData = data.accompaniedCount.toString();

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }

      if (data.childCount && +data.childCount !== data.resource.childCount) {
        const actionName = BookingTimelineActions.CHILDREN_COUNT;
        const oldData = data.resource.childCount
          ? data.resource.childCount.toString()
          : "";
        const newData = data.childCount.toString();

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }

      if (data.date && data.date !== data.resource.date) {
        const actionName = BookingTimelineActions.DATE;
        const oldData = data.resource.date ? data.resource.date : "";
        const newData = data.date;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }

      if (
        data.times &&
        !isEquivalentArrays(Array.from(data.times), data.resource.timeSlots)
      ) {
        const actionName = BookingTimelineActions.TIME_SLOT;
        const oldData = timeSlotsGetNames({
          listing: data.timeSlotsListing,
          timeSlots: new Set<string>(data.resource.timeSlots),
        });
        const newData = timeSlotsGetNames({
          listing: data.timeSlotsListing,
          timeSlots: new Set<string>(data.times),
        });

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }

      if (data.statusID && data.statusID !== data.resource.statusID) {
        const actionName = BookingTimelineActions.STATUS;
        const oldData = await statusesGetName({
          id: data.resource.statusID,
          listing: data.statusesListing,
        });
        const newData = await statusesGetName({
          id: data.statusID,
          listing: data.statusesListing,
        });

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }
      if (data.tableName && data.tableName !== data.resource.tableName) {
        const actionName = BookingTimelineActions.TABLE;
        const oldData = data.resource.tableName ? data.resource.tableName : "";
        const newData = data.tableName;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }
    }

    if (data.comment) {
      console.log(data.resource);
      const actionName = TimelineActions.DELETE_COMMENT;
      const createInput: CreateTimelineInput = {
        actionName: actionName,
        oldStatus: "",
        newStatus: data.resource.message,
        bookingId: data.resource.bookingId,
        customerId: "",
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const timeline = await DataStore.save(new Timeline(createInput as any));

      dispatch(setListing([timeline, ...data.timelinesListing]));
    }

    if (data.customerId) {
      if (
        data.flags &&
        data.flags.length > 0 &&
        data.flags !== data.resource.flags
      ) {
        let actionName = TimelineActions.ADD_FLAG;

        if (data.flags.length < data.resource.flags.length)
          actionName = TimelineActions.REMOVE_FLAG;

        const oldData = mergeListOfStringsByDash(data.resource.flagsName);
        const newData = mergeListOfStringsByDash(data.flagsName);

        if (newData) {
          const createInput: CreateTimelineInput = {
            actionName: actionName,
            oldStatus: oldData,
            newStatus: newData,
            bookingId: "",
            customerId: data.customerId,
            deleted: "0",
            createdAt: new Date().toLocaleString(),
            createdByID: userID,
            createdByName: userName,
          };

          const timeline = await DataStore.save(
            new Timeline(createInput as any)
          );

          dispatch(setListing([timeline, ...data.timelinesListing]));
        }
      }

      if (data.interests && data.interests !== data.resource.interests) {
        let actionName = TimelineActions.ADD_INTEREST;

        if (data.interests.length < data.resource.interests.length)
          actionName = TimelineActions.REMOVE_INTEREST;

        const oldData = mergeListOfStringsByDash(data.resource.interestsName);
        const newData = mergeListOfStringsByDash(data.interestsName);

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }

      if (
        data.phone_number &&
        data.phone_number !== data.resource.phone_number
      ) {
        const actionName = TimelineActions.CHANGE_PHONE_NUMBER;
        const oldData = data.resource.phone_number
          ? data.resource.phone_number
          : "";
        const newData = data.phone_number;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }

      if (
        data.email_verified &&
        data.email_verified !== data.resource.email_verified
      ) {
        const actionName = TimelineActions.CHANGE_VERIFICATION_STATUS;
        const oldData = data.resource.email_verified
          ? data.resource.email_verified
          : "";
        const newData = data.email_verified;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }

      if (data.email && data.email !== data.resource.email) {
        const actionName = TimelineActions.CHANGE_EMAIL;
        const oldData = data.resource.email ? data.resource.email : "";
        const newData = data.email;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }

      if (data.group && data.group !== data.resource.group) {
        const actionName = TimelineActions.CHANGE_GROUP;
        const oldData = data.resource.group ? data.resource.group : "";
        const newData = data.group;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }

      if (data.name && data.name !== data.resource.name) {
        const actionName = TimelineActions.CHANGE_NAME;
        const oldData = data.resource.name ? data.resource.name : "";
        const newData = data.name;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }
      if (data.address && data.address !== data.resource.address) {
        const actionName = TimelineActions.CHANGE_ADDRESS;
        const oldData = data.resource.address ? data.resource.address : "";
        const newData = data.address;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: "",
          newStatus: "modefied address",
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }

      if (data.instagram && data.instagram !== data.resource.instagram) {
        const actionName = TimelineActions.CHANGE_INSTAGRAM;
        const oldData = data.resource.instagram ? data.resource.instagram : "";
        const newData = data.instagram;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }

      if (data.facebook && data.facebook !== data.resource.facebook) {
        const actionName = TimelineActions.CHANGE_FACEBOOK;
        const oldData = data.resource.facebook ? data.resource.facebook : "";
        const newData = data.facebook;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...data.timelinesListing]));
      }
    }
  }

  async function createOnline(params: CreateOnlineVariables, isAuth = null) {
    try {
      const createInput: CreateTimelineInput = {
        actionName: params.actionName!,
        oldStatus: params.oldStatus!,
        newStatus: params.newStatus!,
        bookingId: params.bookingId!,
        customerId: params.customerId!,
        createdByID: params.createdByID!,
        createdByName: params.createdByName!,
        deleted: "0",
        createdAt: new Date().toISOString(),
      };

      const timeLine = await API.graphql<GraphQLQuery<Timeline>>({
        query: createTimeline,
        variables: { input: createInput },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      // return transaction;
      // showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: TimelineUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      await DataStore.save(
        Timeline.copyOf(original!, (updated) => {
          updated.actionName = data.actionName
            ? data.actionName
            : original!.actionName;
          updated.oldStatus = data.oldStatus
            ? data.oldStatus
            : original!.oldStatus;
          updated.newStatus = data.newStatus
            ? data.newStatus
            : original!.newStatus;
          updated.bookingId = data.bookingId
            ? data.bookingId
            : original!.bookingId;
          updated.customerId = data.customerId
            ? data.customerId
            : original!.customerId;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: TimelineGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Timeline.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: TimelineBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: TimelineGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: Timeline[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.actionName, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "category"];

  const api: any = {};

  api[`${listingName}Model`] = Timeline as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}CreateOnline`] = createOnline;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Timeline[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
