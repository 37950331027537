import { guestByBookingID, listBookingGuests } from "./../graphql/queries";
import { GraphQLQuery, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import {
  CreateVariables,
  BookingGuestBulkTrashVariables,
  BookingGuestUpdateVariables,
} from "./../models/app";
import { API, DataStore, SortDirection, graphqlOperation } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setListing, setSelected } from "../store/ducks/bookingGuest";
import { HeadCell } from "../models/dataTable";
import { BookingGuest } from "../models";
import useApp from "./useApp";
import {
  BookingGuestGetVariables,
  BookingGuestListingVariables,
} from "../models/app";
import { CreateBookingGuestInput } from "../models/GQL_API";
import { updateBookingGuest } from "../graphql/mutations";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: BookingGuestListingVariables) {
    const {
      conceptID,
      bookingID,
      transactionID,
      startIndex,
      limit,
      searchText,
    } = params;

    try {
      const listing: BookingGuest[] = await DataStore.query(
        BookingGuest as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (conceptID) model.conceptID("eq", conceptID);
          if (bookingID) model.bookingID("eq", bookingID);
          if (transactionID && transactionID.trim().length > 0)
            model.transactionID("eq", transactionID);

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );
      if (listing) return listing;
      else return [];
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function fetchOnline(
    params: BookingGuestListingVariables,
    session = null
  ) {
    const { conceptID, bookingID, transactionID, limit, searchText } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (conceptID) filter.conceptID = { eq: conceptID };
      if (bookingID) filter.bookingID = { eq: bookingID };
      if (transactionID) filter.transactionID = { eq: transactionID };
      if (searchText) filter.name = { contains: searchText.toLowerCase() };

      const listing: any = await API.graphql({
        query: listBookingGuests,
        variables: { filter, limit: limit },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      if (listing) return listing.data.listBookingGuests.items;
      else return [];
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function fetchWithGuestList(params: any) {
    const {
      conceptID,
      bookingID,
      transactionID,
      startIndex,
      limit,
      bookingIDs,
      searchText,
    } = params;

    try {
      const listing: BookingGuest[] = await DataStore.query(
        BookingGuest as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (conceptID) model.conceptID("eq", conceptID);
          if (bookingID) model.bookingID("eq", bookingID);

          if (bookingIDs) {
            model.or((model2: any) => {
              for (let filter of bookingIDs) {
                model2.bookingID("eq", filter);
              }
            });
          }
          if (transactionID) model.transactionID("eq", transactionID);

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );
      if (listing) return listing;
      else return [];
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  const fetchGuestsByBookingIDs = async (bookingIDs: string[]) => {
    try {
      const guestPromises = bookingIDs.map(async (bookingID) => {
        const result: any = await API.graphql(
          graphqlOperation(guestByBookingID, { bookingID })
        );
        return result.data.guestByBookingID.items;
      });

      const guestsArray = await Promise.all(guestPromises);
      const guests = guestsArray.flat(); // Flatten the array of arrays

      if (guests) return guests;
    } catch (error) {
      console.error("Error fetching guests:", error);
    }
  };

  async function get(params: BookingGuestGetVariables) {
    const { id, listing } = params;

    try {
      const single: BookingGuest | undefined =
        listing.length === 0
          ? await DataStore.query(BookingGuest as any, id)
          : listing.find((model: BookingGuest) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateBookingGuestInput = {
        bookingID: data.bookingID,
        guestName: data.guestName,
        paidAmount: data.paidAmount ? parseFloat(data.paidAmount) : 0,
        paymentAmount: data.paymentAmount ? parseFloat(data.paymentAmount) : 0,
        paymentStatus: data.paymentStatus,
        transactionID: data.transactionID,

        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const model = await DataStore.save(new BookingGuest(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);

      return model;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: BookingGuestUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      await DataStore.save(
        BookingGuest.copyOf(original!, (updated) => {
          updated.guestName = data.guestName
            ? data.guestName
            : original!.guestName;
          updated.paidAmount = data.paidAmount
            ? parseFloat(data.paidAmount)
            : original!.paidAmount;
          updated.paymentAmount = data.paymentAmount
            ? parseFloat(data.paymentAmount)
            : original!.paymentAmount;
          updated.paymentStatus = data.paymentStatus;
          updated.transactionID = data.transactionID
            ? data.transactionID
            : original!.transactionID;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function updateDepositValue(bookingID: string, depositValue: string) {
    try {
      // Get guest list of this booking
      const params: BookingGuestListingVariables = {
        searchText: "",
        startIndex: 0,
        limit: 1000,
        bookingID: bookingID,
      };

      const guests = await fetch(params);

      // Update guest
      let updateFlag = true;
      if (guests && guests.length > 0) {
        for (let guest of guests) {
          if (guest.paidAmount > parseFloat(depositValue)) {
            updateFlag = false;
            break;
          }
        }
        if (updateFlag) {
          for (let guest of guests) {
            let paymentStatus =
              parseFloat(depositValue) === guest.paidAmount ? true : false;

            await DataStore.save(
              BookingGuest.copyOf(guest, (updated) => {
                updated.paymentAmount = parseFloat(depositValue);
                updated.paymentStatus = paymentStatus;
              })
            );
          }
        }
      }

      // showConfirm(`${singleName} has been updated successfully`);
      return updateFlag;
    } catch (err) {
      showError(err);
      return false;
    }
  }

  async function updateOnline(params: any, session = null) {
    try {
      const transaction: any = await API.graphql<GraphQLQuery<BookingGuest>>({
        query: updateBookingGuest,
        variables: { input: params },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return transaction;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: BookingGuestGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        BookingGuest.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: BookingGuestBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: BookingGuestGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "bookingID",
      numeric: false,
      disablePadding: false,
      label: "Booking ID",
    },
    {
      id: "transactionID",
      numeric: false,
      disablePadding: false,
      label: "Transaction ID",
    },
    {
      id: "guestName",
      numeric: false,
      disablePadding: false,
      label: "Guest Name",
    },
    {
      id: "paidAmount",
      numeric: false,
      disablePadding: false,
      label: "Paid Amount",
    },
    {
      id: "paymentAmount",
      numeric: false,
      disablePadding: false,
      label: "Payment Amount",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = [
    "bookingID",
    "transactionID",
    "guestName",
    "paidAmount",
    "paymentAmount",
  ];

  const api: any = {};

  api[`${listingName}Model`] = BookingGuest as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;

  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchOnline`] = fetchOnline;
  api[`${listingName}FetchWithGuestList`] = fetchWithGuestList;
  api[`${listingName}FetchGuestsByBookingIDs`] = fetchGuestsByBookingIDs;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}UpdateDepositValue`] = updateDepositValue;
  api[`${listingName}UpdateOnline`] = updateOnline;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;

  api[`${listingName}ChangeListing`] = (listing: BookingGuest[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  return api;
};

export default useResource;
