import { Auth, API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing
} from "../store/ducks/dispatchers";
import { HeadCell } from "../models/dataTable";
import { AdminsApiPaths, AdminsConstants } from "../constants/enums";
import useApp from "./useApp";
import awsmobile from "../aws-exports";


const useDispatchers = (listingName: string, singleName: string) => {

  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );
  const session = useSelector((state: any) => state.app.session);

  const COGNITO_GROUPS = [{ label: "dispatcher", value: "dispatcher" }];
  const DEVELOP_COGNITO_USER_POOL_ID = awsmobile.aws_user_pools_id;
  const DEVELOP_COGNITO_IDENTITY_POOL_ID = awsmobile.aws_cognito_identity_pool_id ;

    async function create(data: any) {
      try {
        const user = await Auth.signUp({
          username: data.data.email,
          password: data.data.password,
          attributes: {
            name: data.data.name,
            email: data.data.email,
          },
        });
  
        await addUserToGroup(
          user.user.getUsername(),
           "dispatcher"
        );
      } catch (err: Error | any) {
        showError(
          typeof err.message === "string" ? err.message : "Error occurred"
        );
      }
    }
    
    async function dispatchersConfig(apiPath: string, queryString: any, body?: any) {
      let apiName = AdminsConstants.ADMINS_API;
      let path = apiPath;
      let myInit = {
        body: body,
        queryStringParameters: queryString,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      };
  
      return {
        apiName,
        path,
        myInit,
      };
    }
    async function addUserToGroup(username: string, groupname: string) {
      try {
        const { apiName, path, myInit } = await dispatchersConfig(
          AdminsApiPaths.ADD_USER_TO_GROUP,
          {},
          {
            username,
            groupname,
          }
        );
        const { ...result } = await API.post(apiName, path, myInit);
        showConfirm(result.message);
      } catch (err: Error | any) {
        showError(err.message);
      }
    }
  

    async function fetch(nextToken: string, searchText: string,limit: number) {
      try {
        const { apiName, path, myInit } = await dispatchersConfig(
          AdminsApiPaths.LIST_USERS_IN_GROUP,
          {
            groupname: "dispatcher",
            limit: limit,
            token: nextToken,
          }
        );
        const { NextToken, ...data } = await API.get(apiName, path, myInit);
        nextToken = NextToken;
        const listing = data.Users;
        let dispatchers =[]
         for(let i=0;i<listing.length;i++)
        {
          let data=
          {
            name:listing[i].Attributes[2].Value,
            id:listing[i].Attributes[3].Value,
          }
          dispatchers.push(data)
        }

        if (searchText && searchText.length > 0)
        {
          let filteredData=dispatchers.filter((item:any)=>{ return item.id.toLowerCase().includes(searchText.toLowerCase())})
          dispatch(setListing(filteredData));
          return filteredData;
        } 
        else
        {
          dispatch(setListing(dispatchers));
           return dispatchers;
        }

      } catch (err: Error | any) {
        showError(
          typeof err.message === "string" ? err.message : "Error occurred"
        );
      }
    }
  
  const headCells: readonly HeadCell[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Email",
    }, 
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
   
  ];
  
  const dataCells: readonly string[] = ["id", "name"];
  
  const api: any = {};

  api[`${listingName}Create`] = create;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}ChangeListing`] = (listing: any[]) =>
   dispatch(setListing(listing));

  return api;
};

export default useDispatchers;
