// Static for all projects
// Taghmisa EGY
export const CURRENCY: string = "EGP";
export const VERSION: string = "version2";
export const RESERVATION_PAYMENT =
  "https://reservation-payment-egypt.vercel.app/reservations/";
export const PUBLIC_KEY: string =
  "egy_pk_live_oYZauusFWOxXpsc5wETnq3eiN9FWUewy";

// Taghmisa KSA
// export const CURRENCY: string = "SAR";
// export const VERSION: string = "version2";
// export const RESERVATION_PAYMENT =
//   "https://reservation-payment.vercel.app/reservations/";
// export const PUBLIC_KEY: string =
//   "sau_pk_live_jazIw6uVD4vloMVSsdilGnvBxP0Yd9om";
